import React, { FC, useState, ReactNode } from 'react';
import _, { isEmpty, upperCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Navbar as BoostrapNavbar,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import HamburgerMenu from 'react-hamburger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import {
  faHome,
  faChevronRight,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { getVars } from '../../libs/provisioning';

import { User } from '../../store/users/types';

import Clock from '../Clock';
import logo from '../../assets/logo_light.png';
import './Navbar.scss';

type navMenuItem = {
  label: string;
  onClick: Function;
  icon?: ReactNode;
  hidden?: boolean;
};
interface Props {
  onToggle: Function;
  sidebarOpen: boolean;
  path: string[];
  menuConfig: navMenuItem[];
  user: User;
  customers: {
    code: string;
    name: string;
  }[];
}

const Navbar: FC<Props> = ({
  onToggle = (): null => null,
  sidebarOpen,
  menuConfig,
  path = [],
  user,
  customers,
}) => {
  const [dropdowOpen, setDropdownOpen] = useState(false);

  const dropdownToggle = (): void => {
    setDropdownOpen((prev) => !prev);
  };

  const history = useHistory();
  const { firstName, lastName, username } = user;

  const customerCode = localStorage.getItem('pki_customer');
  const customer = _.find(customers, (item) => item.code === customerCode);
  const { ENV } = getVars();

  return (
    <div className="Navbar text-light">
      <BoostrapNavbar color="dark" light expand="md">
        <span className="cursor-pointer mt-2">
          <HamburgerMenu
            isOpen={sidebarOpen}
            color="white"
            height={14}
            width={20}
            menuClicked={(): void => {
              onToggle();
            }}
          />
        </span>
        <NavbarBrand className="mx-4" href="/">
          <img src={logo} alt="logo" width="100" />
        </NavbarBrand>
        <Nav className="ml-5 mr-auto mt-2" navbar>
          <NavItem className="mr-2">
            <FontAwesomeIcon
              onClick={(): void => {
                history.push('/');
              }}
              className="cursor-pointer"
              icon={faHome}
            />
          </NavItem>
          {_.map(path, (item, index) => (
            <div className="d-flex" key={index}>
              <NavItem className="mr-2">
                <FontAwesomeIcon icon={faChevronRight} />
              </NavItem>
              <NavItem
                onClick={(): void => {
                  const formattedItems = _.map(path, (i) =>
                    _.kebabCase(i.toLowerCase())
                  );
                  const url = _.join(
                    _.slice(formattedItems, 0, index + 1),
                    '/'
                  );
                  history.push(`/${url}`);
                }}
                className="cursor-pointer mr-2"
              >
                {item}
              </NavItem>
            </div>
          ))}
        </Nav>
        <div className="mt-2">
          <div className="d-flex">
            <Clock />
            <span className="mx-2">
              {!_.isNil(firstName) && !_.isNil(lastName)
                ? `${firstName} ${lastName}`
                : username}
            </span>
            <span
              onClick={(): void => {
                dropdownToggle();
              }}
              id="open-menu"
              className="cursor-pointer"
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </div>
          <div className="dropdown position-absolute">
            {_.map(menuConfig, (item, index) => (
              <div key={index}>
                {!item.hidden && (
                  <div
                    className={classnames({
                      'p-2 pl-3 cursor-pointer': true,
                      'd-none': !dropdowOpen,
                    })}
                    onClick={(): void => {
                      item.onClick();
                      setDropdownOpen(false);
                    }}
                  >
                    {item.icon}
                    <span className="ml-1">{item.label}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="text-center">
            {customer?.name}{' '}
            {!isEmpty(ENV) &&
              upperCase(ENV) !== 'LIVE' &&
              `(${upperCase(ENV)})`}
          </div>
        </div>
      </BoostrapNavbar>
    </div>
  );
};

export default Navbar;
