import React, { FC, useEffect, useRef, useState } from 'react';
import _, { includes, isEmpty } from 'lodash';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledPopover,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SmartSelect from 'react-select';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';
import { Select, SelectNInput, GenericList } from '../../components/index';
import {
  generalDefaultValues as defaultValues,
  keySpecOptions,
  subjectOptions,
  subjectOptionsDropdown,
  subjectPreviewKeys,
} from '../../libs/constants';
import { certSubjectValidation, keyToValue } from './helpers';

import { General as GeneralType } from '../../libs/types';
import './Components.scss';
import { Certificate } from '../../store/certificates/types';
import { api } from '../../libs/helpers';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { sendNotification } from '../../store/notifications/actions';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';

interface Props {
  id?: string;
  onChange?: Function;
  disabledFields?: string[];
  highlightMandatoryFields?: boolean;
  values: GeneralType;
  readOnly?: boolean;
  isGettingKeySpecs?: boolean;
  currentSan?: { key: string; value: string };
  reformattedSan: { key: string; value: string }[];
  subjectSorted: { key: string; value: string }[];
  setSelectedIssuingCa?: Function;
  keySpecs?: {
    keySpec: { key: string; value: string }[];
    signatureAlgorithm: { key: string; value: string }[];
    signatureHashAlgorithm: { key: string; value: string }[];
  };
  type: 'PROFILE' | 'TEMPLATE';
}

const General: FC<Props> = ({
  id = '',
  disabledFields = [],
  onChange = (): null => null,
  highlightMandatoryFields = false,
  values = defaultValues,
  isGettingKeySpecs,
  reformattedSan = [],
  subjectSorted = [],
  readOnly = false,
  keySpecs,
  type,
  setSelectedIssuingCa,
}) => {
  const [currentSubject, setCurrentSubject] = useState<{
    key: string;
    value: string;
  }>({ key: '', value: '' });

  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);
  const fetchCancelTokenSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const {
    name,
    profileId,
    issuingCaUuid,
    issuerCN,
    validity,
    keySpec,
    signatureAlgorithm,
    signatureHashAlgorithm,
    subject,
    description,
  } = values;

  const isIdValid = profileId
    ? encodeURIComponent(profileId) === profileId
    : true;
  const isNameValid =
    (_.isEqual(name, defaultValues.name) ||
      !_.chain(name).trim().isEmpty().value()) &&
    !(_.chain(name).trim().isEmpty().value() && highlightMandatoryFields);
  const isValidityValid = validity && validity >= 1 && validity <= 1200;

  const fetchCurrentIssuingCA = async () => {
    fetchCancelTokenSource.current = axios.CancelToken.source();
    try {
      const { data: ca } = await api().get(
        `/certificate/authority/${issuingCaUuid}`,
        {
          cancelToken: fetchCancelTokenSource.current?.token,
        }
      );

      setCurrentIssuingCA(() => {
        if (setSelectedIssuingCa)
          setSelectedIssuingCa(deserializeCertificate(ca));
        return deserializeCertificate(ca);
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        sendNotification({
          text: err?.response?.data?.detail || 'Failed to load the data!',
          success: false,
        })(dispatch);
      }
    }
  };
  useEffect(() => {
    if (issuingCaUuid) {
      fetchCurrentIssuingCA();
    }
    return () => {
      fetchCancelTokenSource.current?.cancel('General component got unmounted');
    };
  }, []);

  const subjectOutput = _(subjectSorted)
    .map(
      (option) => `${keyToValue(subjectOptions)[option.key]}: ${option.value}`
    )
    .value();
  const validationSAN = ((): { isInvalid: boolean; message: string } => {
    return certSubjectValidation(currentSubject, subjectSorted, reformattedSan);
  })();

  let invalidSubject;
  if (
    (highlightMandatoryFields && _.size(subject) < 1) ||
    (highlightMandatoryFields &&
      type === 'PROFILE' &&
      !_.find(subject, (item) => item.key === 'common_name'))
  ) {
    invalidSubject =
      type === 'PROFILE'
        ? 'Subject must contain at least one entry. Common name (CN) is mandatory.'
        : 'Subject must contain at least one entry.';
  }

  return (
    <div id={id} className="General">
      <div className="general-common mt-3">
        <Row form>
          {!_.includes(disabledFields, 'name') && (
            <Col>
              <FormGroup>
                <Label className="pki-label" for="name">
                  Name *
                </Label>
                <Input
                  id="name"
                  invalid={!isNameValid}
                  value={name}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    onChange({ key: 'name', value: event.target.value });
                  }}
                  type="text"
                  name="name"
                  placeholder="Indicate certificate purpose (e.g. TLS)"
                />
                <FormFeedback>Cannot be empty</FormFeedback>
              </FormGroup>
            </Col>
          )}
          {!_.includes(disabledFields, 'profileId') && (
            <Col>
              <FormGroup>
                <Label className="pki-label" for="profileId">
                  REST API ID
                </Label>
                <FontAwesomeIcon
                  id="profileId-hint"
                  icon={faInfoCircle}
                  className="pki-ico ml-1"
                />
                <UncontrolledPopover trigger="hover" target="profileId-hint">
                  <div className="p-2">
                    <small>
                      You can specify the certificate_profile_id to be used in
                      the REST API.
                      <br />
                      If not specified, an identifier (uuid) will be generated.
                    </small>
                  </div>
                </UncontrolledPopover>
                <Input
                  id="profileId"
                  invalid={!isIdValid}
                  value={profileId}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    const value = _.isEmpty(event.target.value)
                      ? undefined
                      : event.target.value;
                    onChange({ key: 'profileId', value });
                  }}
                  type="text"
                  name="profileId"
                  placeholder="(Optional) certificate_profile_id to be used in the REST API"
                />
                <FormFeedback>
                  Only <code>{`A-Za-z0-9-_.!~*'()`}</code> characters allowed
                </FormFeedback>
              </FormGroup>
            </Col>
          )}
          <Col md={2}>
            <FormGroup>
              <Label className="pki-label" for="validity">
                Validity (months)
              </Label>
              <Input
                id="validity"
                value={validity}
                readOnly={readOnly}
                plaintext={readOnly}
                min="1"
                max="1200"
                invalid={!isValidityValid}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  onChange({
                    key: 'validity',
                    value: Number(event.target.value),
                  });
                }}
                type="number"
                name="validity"
              />
              {!isValidityValid && (
                <FormFeedback>
                  It must be a number between 1 and 1200
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
          {!_.includes(disabledFields, 'authority') && (
            <>
              <Col md={12}>
                <FormGroup>
                  <Label className="pki-label" for="authority">
                    Issuing CA
                  </Label>
                  {readOnly && (
                    <Input
                      value={currentIssuingCA?.commonName || issuerCN || 'N/A'}
                      readOnly={true}
                      plaintext={true}
                      type="text"
                      name="authority"
                      id="authority"
                    />
                  )}
                  {!readOnly && (
                    <>
                      <ServerSideSelect
                        onSelectEntity={(ca) => {
                          onChange({
                            key: 'issuingCaUuid',
                            value: ca?.uuid,
                          });
                          setCurrentIssuingCA(() => {
                            if (setSelectedIssuingCa)
                              setSelectedIssuingCa(deserializeCertificate(ca));
                            return deserializeCertificate(ca);
                          });
                        }}
                        formatter={(ca) => ca.cn}
                        fetchUrl={`certificate/authority`}
                        defaultFilters={'is_key_online=true'}
                        searchParam={`cn`}
                        value={currentIssuingCA?.commonName || ''}
                      />
                    </>
                  )}
                </FormGroup>
              </Col>
            </>
          )}
          {!_.includes(disabledFields, 'description') && (
            <Col md={12}>
              <Label className="pki-label" for="description">
                Description
              </Label>
              {readOnly && (
                <p className="py-2">
                  {_.isEmpty(description) ? 'N/A' : description}
                </p>
              )}
              {!readOnly && (
                <FormGroup>
                  <Input
                    value={
                      readOnly && _.isEmpty(description) ? 'N/A' : description
                    }
                    type="textarea"
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChange({
                        key: 'description',
                        value: event.target.value,
                      });
                    }}
                    name="description"
                    id="description"
                  />
                </FormGroup>
              )}
            </Col>
          )}
          {!includes(disabledFields, 'keySpec') && (
            <Col>
              <FormGroup>
                <Select
                  id="key-algorithm"
                  selectedKey={keySpec}
                  readOnly={readOnly}
                  disabled={
                    type === 'PROFILE' ? isEmpty(keySpecs?.keySpec) : false
                  }
                  onChange={({ key }: { key: string }): void => {
                    onChange({ key: 'keySpec', value: key });
                  }}
                  options={
                    type === 'PROFILE'
                      ? keySpecs?.keySpec || []
                      : keySpecOptions
                  }
                  label={
                    <>
                      Key Specification{' '}
                      {isGettingKeySpecs && (
                        <Spinner
                          className="ml-1 pki-ico"
                          style={{
                            width: '11px',
                            height: '11px',
                            marginBottom: '2px',
                          }}
                        />
                      )}
                    </>
                  }
                />
              </FormGroup>
            </Col>
          )}
          {!includes(disabledFields, 'signatureAlgorithm') && (
            <Col>
              <FormGroup>
                <Select
                  id="signature-algorithm"
                  selectedKey={signatureAlgorithm}
                  readOnly={readOnly}
                  onChange={({ key }: { key: string }): void => {
                    onChange({ key: 'signatureAlgorithm', value: key });
                  }}
                  disabled={isEmpty(keySpecs?.signatureAlgorithm)}
                  options={keySpecs?.signatureAlgorithm || []}
                  label={
                    <>
                      Signature Algorithm{' '}
                      {isGettingKeySpecs && (
                        <Spinner
                          className="ml-1 pki-ico"
                          style={{
                            width: '11px',
                            height: '11px',
                            marginBottom: '2px',
                          }}
                        />
                      )}
                    </>
                  }
                />
              </FormGroup>
            </Col>
          )}
          {!includes(disabledFields, 'signatureHashAlgorithm') && (
            <Col>
              <FormGroup>
                <Select
                  id="signature-hash-algorithm"
                  selectedKey={signatureHashAlgorithm}
                  readOnly={readOnly}
                  onChange={({ key }: { key: string }): void => {
                    onChange({ key: 'signatureHashAlgorithm', value: key });
                  }}
                  disabled={isEmpty(keySpecs?.signatureHashAlgorithm)}
                  options={keySpecs?.signatureHashAlgorithm || []}
                  label={
                    <>
                      Signature Hash Algorithm{' '}
                      {isGettingKeySpecs && (
                        <Spinner
                          className="ml-1 pki-ico"
                          style={{
                            width: '11px',
                            height: '11px',
                            marginBottom: '2px',
                          }}
                        />
                      )}
                    </>
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      </div>
      <div className="general-subject">
        {!readOnly && (
          <>
            <Label className="pki-label">Subject</Label>
            <FontAwesomeIcon
              id="subject-hint"
              icon={faInfoCircle}
              className="pki-ico ml-1"
            />
            <UncontrolledPopover trigger="hover" target="subject-hint">
              <div className="p-2">
                <small>
                  Variables can be added with <code>{`{ }`}</code> to be
                  substituted with a value during certificate generation. For
                  more information about certificate variables see the Concept
                  Guide. <br />
                  Examples:
                  <br />-{' '}
                  <code>{`prefix-{var1:optional|Default Value}-{var2:required}-suffix`}</code>
                  <br />
                </small>
              </div>
            </UncontrolledPopover>
            <SelectNInput
              id="general-subject-input"
              disabled={_.isEmpty(subjectOptions)}
              onAdd={({
                selectKey,
                inputValue,
              }: {
                selectKey: string;
                inputValue: string;
              }): void => {
                onChange({
                  key: 'subject',
                  value: [...subject, { key: selectKey, value: inputValue }],
                });
              }}
              invalidInput={{
                condition: validationSAN.isInvalid,
                message: validationSAN.message,
              }}
              onInputChange={({
                selectValue,
                inputValue,
              }: {
                selectValue: string;
                inputValue: string;
              }): void => {
                setCurrentSubject({ key: selectValue, value: inputValue });
              }}
              options={subjectOptionsDropdown}
            />
            {invalidSubject && highlightMandatoryFields && (
              <div className="invalid-text subject-error">{invalidSubject}</div>
            )}
            <GenericList
              id="general-subject-output"
              data={subjectOutput}
              readOnly={readOnly}
              label={readOnly ? 'Subject' : ''}
              onDelete={({ value }: { value: string }): void => {
                const valueToFilter = value
                  .substring(value.indexOf(': ') + 1)
                  .trim();
                const newValues = _.filter(
                  subject,
                  (item, itemIndex) => item.value !== valueToFilter
                );
                onChange({ key: 'subject', value: newValues });
              }}
            />
          </>
        )}
        {!_.isEmpty(subjectSorted) && (
          <div className="subject-preview">
            <Label className="pki-label">Subject Preview</Label>
            <br />
            <code className="black-code">
              {_.map(subjectSorted, (item, index) => (
                <span className="ml-1" key={index}>
                  {_.get(subjectPreviewKeys, `${item.key}`)}={item.value}
                  {index + 1 < _.size(subjectSorted) ? ',' : ''}
                </span>
              ))}
            </code>
          </div>
        )}
      </div>
    </div>
  );
};

export default General;
