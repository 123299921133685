import moment from 'moment/moment';
import _, { cloneDeep } from 'lodash';
import { deserializeUserOutput } from '../../../store/users/helpers';
import { ProductProfile } from '../types';
import { deserializePGPKey } from '../../../libs/helpers';
import { CertificateProfile } from '../../../store/certificateProfiles/types';
import { deserializeCertificateProfile } from '../../../store/certificateProfiles/helpers';

export const serializeProductProfileCertificateProfilesUuids = (
  certificateProfileUuids: ProductProfile['certificateProfileUuids']
) => {
  const results: {
    [key: string]: string;
  } = {};
  (certificateProfileUuids || []).forEach(({ uuid, label }) => {
    results[label] = uuid || '';
  });
  return results;
};

export const serializeProductProfile = (
  productProfile: Partial<ProductProfile>
) => {
  return {
    ...(productProfile?.uuid && { uuid: productProfile.uuid }),
    ...(productProfile?.name && { name: productProfile.name }),
    ...(productProfile?.notes && { notes: productProfile.notes }),
    ...(productProfile?.productProfileId && {
      product_profile_id: productProfile.productProfileId,
    }),
    ...(productProfile?.metadata && { metadata: productProfile.metadata }),
    ...(productProfile?.secrets && { secret_data: productProfile.secrets }),
    ...(productProfile?.output && { output: productProfile.output }),
    ...(productProfile?.certificateProfiles && {
      certificate_profile_uuids: serializeProductProfileCertificateProfilesUuids(
        productProfile.certificateProfileUuids
      ),
    }),
    ...(productProfile?.pgpKeys && {
      pgp_key_uuids: productProfile.pgpKeys.map((pgpKey) => pgpKey.uuid),
    }),
  };
};

export const deserializeProductProfile = (raw: any): ProductProfile => {
  const certificateProfileUuids: {
    label: string;
    uuid: string;
  }[] = (Object.entries(raw.certificate_profile_uuids || {}) as [
    label: string,
    uuid: string
  ][]).map(([label, uuid]) => {
    return {
      label,
      uuid,
    };
  });
  const certificateProfiles: {
    label: string;
    certificateProfile: CertificateProfile;
  }[] = Object.entries(raw.certificate_profiles || {}).map(
    ([label, certificateProfile]: [label: string, certificateProfile: any]) => {
      return {
        label,
        certificateProfile: deserializeCertificateProfile(certificateProfile),
      };
    }
  );
  return {
    productProfileId: raw.product_profile_id,
    name: raw.name,
    uuid: raw.uuid,
    notes: raw.notes || '',
    actions: raw.actions,
    metadata: raw.metadata || [],
    secrets: raw.secret_data || [],
    output: raw.output || [],
    certificateProfiles: certificateProfiles || [],
    certificateProfileUuids,
    pgpKeys: raw?.pgp_keys
      ? raw.pgp_keys.map((pgpKey: any) => deserializePGPKey(pgpKey))
      : [],
    updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format(
      'LLL'
    ),
    createdAt: moment(raw.date_created).format('LLL'),
    ...(raw?.updated_by_user || raw?.created_by_user
      ? {
          updatedBy: deserializeUserOutput(
            raw?.updated_by_user || raw?.created_by_user
          ),
        }
      : {}),
    ...(raw?.created_by_user
      ? {
          createdBy: deserializeUserOutput(raw.created_by_user),
        }
      : {}),
  };
};

export const swapArrayLoc = (arr: any[], from: number, to: number) => {
  const newArr = cloneDeep(arr);
  newArr.splice(from, 1, newArr.splice(to, 1, newArr[from])[0]);
  return newArr;
};
