import React, { useState, FC, useRef, useEffect } from 'react';
import _, { get, isEmpty, map } from 'lodash';
import SmartSelect from 'react-select';
import { Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';

interface InputNAddInputI {
  id?: string;
  label?: string;
  suggestions?: string[];
  invalid?: {
    condition: boolean;
    message?: string;
  };
  onChange?: Function;
  onAdd?: Function;
  placeholder?: string;
}

const InputNAdd: FC<InputNAddInputI> = ({
  id = '',
  label = '',
  invalid = {},
  onChange = (): null => null,
  onAdd = (): null => null,
  placeholder = '',
  suggestions = [],
}) => {
  const [value, setValue] = useState('');
  const [suggestionsClone, setSuggestionsClone] = useState(suggestions);
  const isNotValid = (invalid && invalid.condition) || !value;
  const selectEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (JSON.stringify(suggestions) !== JSON.stringify(suggestionsClone))
      setSuggestionsClone(suggestions);
  }, [suggestions, suggestionsClone]);

  const clearSelect = (): void => {
    if (selectEl?.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (selectEl?.current as any).setState({
        menuIsOpen: false,
        value: '',
        inputValue: '',
      });
    }
    setSuggestionsClone(suggestions);
  };

  return (
    <div id={id} className="InputNAdd">
      <Label className="pki-label">{label}</Label>
      <Row form>
        <Col md={11}>
          <FormGroup>
            {isEmpty(suggestions) && (
              <Input
                invalid={!_.isEmpty(value) && invalid && invalid.condition}
                onKeyPress={(
                  event: React.KeyboardEvent<HTMLInputElement>
                ): void => {
                  if (event.key === 'Enter' && !isNotValid) {
                    onAdd(value);
                    setValue('');
                  }
                }}
                value={value}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  onChange(event.target.value);
                  setValue(event.target.value);
                }}
                type="text"
                placeholder={placeholder}
              />
            )}

            {!isEmpty(suggestions) && (
              <SmartSelect
                isSearchable={true}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ref={selectEl as any}
                isMulti={false}
                placeholder={placeholder}
                isClearable={true}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onKeyDown={(event: any): void => {
                  if (get(event, 'key') === 'Enter' && !isNotValid) {
                    event?.preventDefault();
                    onAdd(value);
                    clearSelect();
                  }
                }}
                options={map(suggestionsClone, (suggestion) => ({
                  value: suggestion,
                  label: suggestion,
                }))}
                onInputChange={(inputValue: string): void => {
                  if (inputValue !== '') {
                    setValue(inputValue);
                    onChange(inputValue);
                    setSuggestionsClone((prev) => {
                      const newValue = [...suggestions];
                      newValue.push(inputValue);
                      return newValue;
                    });
                  }
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(selectedValue: any): void => {
                  const newValue = get(selectedValue, 'value');
                  setValue(newValue);
                  onChange(newValue);
                }}
              />
            )}
            {!_.isEmpty(value) &&
              invalid &&
              invalid.condition &&
              invalid.message && (
                <div className="invalid-text mt-2">{invalid.message}</div>
              )}
          </FormGroup>
        </Col>
        <Col md={1}>
          <div className="button-container float-right">
            <Button
              id="add-button"
              outline
              disabled={isNotValid}
              onClick={(): void => {
                onAdd(value);
                setValue('');
                clearSelect();
              }}
            >
              Add
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InputNAdd;
