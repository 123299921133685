import React, {
  ChangeEventHandler,
  FC,
  ReactEventHandler,
  useEffect,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Spinner } from '../../../../../../../components';
import { ServerSideSelect } from '../../../../../../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificate } from '../../../../../../../store/certificates/helpers';
import { deserializeCertificateProfile } from '../../../../../../../store/certificateProfiles/helpers';
import { Certificate } from '../../../../../../../store/certificates/types';
import { CertificateProfile } from '../../../../../../../store/certificateProfiles/types';

interface AddCertificateProfileFormProps {
  onSubmit: (certificateProfileId: string, label: string) => void;
  isLoading?: boolean;
  editMode?: boolean;
  onCancel: Function;
  profileId: string;
}

export const AddCertificateProfileForm: FC<AddCertificateProfileFormProps> = ({
  onSubmit,
  isLoading = false,
  editMode = false,
  onCancel,
  profileId,
}) => {
  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);

  const [currentCertificateProfile, setCurrentCertificateProfile] = useState<
    CertificateProfile | undefined
  >(undefined);

  const [label, setLabel] = useState('');

  const [showErrors, setShowErrors] = useState(false);

  const validateAndSubmit = () => {
    setShowErrors(true);
    if (!currentCertificateProfile?.uuid || !label) return;
    onSubmit(currentCertificateProfile.uuid, label);
  };

  const onChangeLabel: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setLabel(ev.target.value);
  };

  return (
    <div id={'ProductProfileCertificateProfileForm'}>
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>Add Certificate Profile</h3>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row form>
          <Col>
            <Label className="pki-label">Issuing CA</Label>
            <ServerSideSelect
              onSelectEntity={(ca) => {
                setCurrentIssuingCA(() => {
                  setShowErrors(false);
                  setCurrentCertificateProfile(undefined);
                  return deserializeCertificate(ca);
                });
              }}
              id="issuer-ca-uuid"
              formatter={(ca) => ca.cn}
              fetchUrl={`certificate/authority`}
              defaultFilters={'is_key_online=true'}
              searchParam={`cn`}
              value={currentIssuingCA?.commonName || ''}
            />
          </Col>
          <Col>
            <Label className="pki-label">Certificate Profile</Label>
            <ServerSideSelect
              onSelectEntity={(profile) => {
                setCurrentCertificateProfile(() => {
                  setShowErrors(false);
                  return deserializeCertificateProfile(profile);
                });
              }}
              formatter={(profile) => profile?.certificate_profile?.name}
              fetchUrl={`certificate/profile`}
              defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
              searchParam={`certificate_profile.name`}
              wait={!currentIssuingCA}
              id="certificate-profile"
              value={
                currentCertificateProfile?.name ||
                'Select a certificate profile'
              }
              error={
                showErrors && !currentCertificateProfile?.uuid
                  ? 'Cannot be empty, please select one!'
                  : undefined
              }
            />
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">Label</Label>
              <Input
                value={label}
                onChange={onChangeLabel}
                invalid={showErrors && !label}
                placeholder={'Unique label for reference in variables'}
              />
              <FormFeedback>Cannot be empty!</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {onSubmit !== undefined && (
          <div className="modal-buttons">
            <div className="float-right mt-5 pb-5 d-flex">
              <>
                <span className="mr-2">
                  <Button
                    id={`close-product-key-form`}
                    outline
                    disabled={isLoading}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    id={`submit-product-profile-add-certificate-form`}
                    outline
                    disabled={isLoading || !currentCertificateProfile?.uuid}
                    onClick={validateAndSubmit}
                  >
                    Confirm
                  </Button>
                </span>
              </>
              {isLoading && (
                <Spinner
                  size={'sm'}
                  className={'ml-2 btn-group-vertical'}
                  type={'border'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
