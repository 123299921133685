import React, { FC, useState } from 'react';
import _, { isEqual } from 'lodash';
import { Row, Col, Label, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CheckboxList,
  SelectNInput,
  GenericList,
  InputNAdd,
  Table,
} from '../../components';
import {
  isValidIP,
  isValidDNS,
  isValidURI,
  isValidRFC822,
} from '../../libs/helpers';
import {
  certSubjectValidation,
  getTableColumns,
  keyToValue,
  formatSanValueArrayToKeyValuePairsArray,
} from './helpers';

import { Extensions as ExtensionsType } from '../../libs/types';

import {
  extensionsDefaultValues as defaultValues,
  keyUsageOptions,
  extendedKeyUsageOptions,
  subjectAlternativeNamesOptions,
  AIAAccessOptions,
} from '../../libs/constants';

interface Props {
  id?: string;
  disabledFields?: string[];
  onChange?: Function;
  CRLSuggestions?: string[];
  AIASuggestions?: string[];
  reformattedSan?: { key: string; value: string }[];
  subjectSorted?: { key: string; value: string }[];
  onReformattedSanChange?: Function;
  authorityList?: string[];
  values: ExtensionsType;
  readOnly?: boolean;
}

const Extensions: FC<Props> = ({
  id = '',
  onChange = (): null => null,
  values = defaultValues,
  disabledFields = [],
  readOnly = false,
  CRLSuggestions = [],
  AIASuggestions = [],
  reformattedSan = [],
  onReformattedSanChange = (): null => null,
  subjectSorted = [],
}) => {
  const [currentSan, setCurrentSan] = useState<{ key: string; value: string }>({
    key: '',
    value: '',
  });
  const [currentCRL, setCurrentCRL] = useState<string>('');
  const [currentAIA, setCurrentAIA] = useState<{ key: string; value: string }>({
    key: '',
    value: '',
  });

  const {
    keyUsage,
    extendedKeyUsage,
    subjectAlternativeNames,
    authorityInformationAccess,
    CRLDistributionPointURIs,
  } = values;

  const onSanChange = ({
    selectValue,
    inputValue,
  }: {
    selectValue: string;
    inputValue: string;
  }): void => {
    setCurrentSan({ key: selectValue, value: inputValue });
  };

  const addKeyValue = ({
    selectKey,
    inputValue,
  }: {
    selectKey: string;
    inputValue: string;
  }): void => {
    const currentElement = _.find(
      subjectAlternativeNames,
      ({ key: currentKey }: { key: string; values: string[] }) =>
        currentKey === selectKey
    );
    let newElement;
    if (_.isNil(currentElement)) {
      newElement = { key: selectKey, values: [inputValue.trim()] };
    } else {
      const currentValues = currentElement.values || [];
      newElement = {
        key: selectKey,
        values: [...currentValues, inputValue.trim()],
      };
    }
    const auxSubjectAlternativeNames = _.filter(
      subjectAlternativeNames,
      ({ key: storedKey }: { key: string; values: string[] }) =>
        storedKey !== selectKey
    );
    const newValue = [...auxSubjectAlternativeNames, newElement];
    onChange({ key: 'subjectAlternativeNames', value: newValue });
  };

  const validationSAN = ((): { isInvalid: boolean; message: string } => {
    if (currentSan.value.includes('{') && currentSan.key !== 'RFC822') {
      const newReformattedSan = formatSanValueArrayToKeyValuePairsArray(
        subjectAlternativeNames
      );
      if (!isEqual(reformattedSan, newReformattedSan)) {
        onReformattedSanChange(newReformattedSan);
      }
      return certSubjectValidation(currentSan, reformattedSan, subjectSorted);
    }
    if (currentSan.key === 'DNS') {
      return {
        isInvalid: !isValidDNS(currentSan.value),
        message: 'Insert a valid DNS',
      };
    }
    if (currentSan.key === 'URI') {
      return {
        isInvalid: !isValidURI(currentSan.value),
        message: 'Insert a valid URI',
      };
    }
    if (currentSan.key === 'RFC822') {
      return {
        isInvalid: !isValidRFC822(currentSan.value),
        message: 'Insert a valid RFC822',
      };
    }
    if (currentSan.key === 'IP') {
      return {
        isInvalid: !isValidIP(currentSan.value),
        message: 'Insert a valid IP',
      };
    }
    return { message: '', isInvalid: false };
  })();

  const validationAIA = ((): { isInvalid: boolean; message: string } => {
    if (currentAIA.key === 'OCSP') {
      return {
        isInvalid: !isValidURI(currentAIA.value),
        message: 'Insert a valid OCSP URI',
      };
    }
    if (currentAIA.key === 'CA_ISSUER') {
      return {
        isInvalid: !isValidURI(currentAIA.value),
        message: 'Insert a valid CA Issuer URI',
      };
    }
    return { message: '', isInvalid: false };
  })();

  return (
    <div id={id} className="Extensions">
      <div className="extension-ku-eku mt-3">
        <Row form>
          <Col>
            <CheckboxList
              id="key-usage"
              label="Key Usage"
              readOnly={readOnly}
              checked={keyUsage}
              onChange={(newValue: string[]): void => {
                onChange({ key: 'keyUsage', value: newValue });
              }}
              options={keyUsageOptions}
            />
          </Col>
          <Col>
            <CheckboxList
              id="extended-key-usage"
              checked={extendedKeyUsage}
              readOnly={readOnly}
              label="Extended Key Usage"
              onChange={(newValue: string[]): void => {
                onChange({ key: 'extendedKeyUsage', value: newValue });
              }}
              options={extendedKeyUsageOptions}
            />
          </Col>
        </Row>
      </div>
      <div className="extension-san mt-3">
        {!readOnly && (
          <div>
            <Label className="pki-label">Subject Alternative Names</Label>
            <FontAwesomeIcon
              id="subject-hint"
              icon={faInfoCircle}
              className="pki-ico ml-1"
            />
            <UncontrolledPopover trigger="hover" target="subject-hint">
              <div className="p-2">
                <small>
                  Variables can be added with <code>{`{ }`}</code> to be
                  substituted with a value during certificate generation. For
                  more information about certificate variables see the Concept
                  Guide. <br />
                  Examples:
                  <br />-{' '}
                  <code>{`prefix-{var1:optional|Default Value}-{var2:required}-suffix`}</code>
                  <br />
                </small>
              </div>
            </UncontrolledPopover>
            <SelectNInput
              id="subject-alternative-names-input"
              invalidInput={{
                condition:
                  !_.isEmpty(currentSan.value) && validationSAN.isInvalid,
                message: validationSAN.message,
              }}
              onAdd={addKeyValue}
              onInputChange={onSanChange}
              options={subjectAlternativeNamesOptions}
            />
          </div>
        )}
        {readOnly && (
          <Label className="pki-label">Subject Alternative Names</Label>
        )}
        <Table
          keyField="id"
          id="subject-alternative-names-output"
          search={false}
          noDataIndication={
            readOnly
              ? 'No Subject Alternative Names'
              : 'Add a New Subject Alternative Names'
          }
          data={_.map(
            subjectAlternativeNames,
            (item: { key: string; values: string[] }, index) => ({
              ...item,
              key: keyToValue(subjectAlternativeNamesOptions)[item.key],
              id: index,
            })
          )}
          columns={getTableColumns(
            'subjectAlternativeNames',
            subjectAlternativeNames,
            ['Type', 'Values', ''],
            ({ id: rowId }: { id: number }) => {
              const newValues = _.filter(
                subjectAlternativeNames,
                (item, itemIndex) => rowId !== itemIndex
              );
              onChange({ key: 'subjectAlternativeNames', value: newValues });
            },
            readOnly
          )}
        />
      </div>
      {!_.includes(disabledFields, 'CRLDistributionPointURIs') && (
        <div className="extension-crl mt-3">
          {!readOnly && (
            <InputNAdd
              suggestions={CRLSuggestions}
              id="CRL-distribution-point-URIs-input"
              label="CRL Distribution Point URIs"
              invalid={{
                condition: !isValidURI(currentCRL),
                message: 'Insert a valid URI',
              }}
              placeholder="Add URI..."
              onChange={(newValue: string): void => {
                setCurrentCRL(newValue);
              }}
              onAdd={(newValue: string): void => {
                onChange({
                  key: 'CRLDistributionPointURIs',
                  value: [...(CRLDistributionPointURIs || []), newValue],
                });
              }}
            />
          )}
          <GenericList
            id="CRL-distribution-point-URIs-output"
            label={readOnly ? 'CRL Distribution Point URIs' : ''}
            readOnly={readOnly}
            data={CRLDistributionPointURIs || []} // toRefactor
            elementsPerCol={3}
            onDelete={({ index }: { index: number }): void => {
              const newValues = _.filter(
                CRLDistributionPointURIs,
                (item, itemIndex) => index !== itemIndex
              );
              if (newValues) {
                onChange({ key: 'CRLDistributionPointURIs', value: newValues });
              }
            }}
          />
        </div>
      )}
      <div className="extension-aia mt-3">
        {!readOnly && (
          <SelectNInput
            id="authority-information-access-input"
            invalidInput={{
              condition:
                !_.isEmpty(currentAIA.value) && validationAIA.isInvalid,
              message: validationAIA.message,
            }}
            onAdd={({
              selectKey,
              inputValue,
            }: {
              selectKey: string;
              inputValue: string;
            }): void => {
              const currentElement = _.find(
                authorityInformationAccess,
                ({ key: currentKey }: { key: string; values: string[] }) =>
                  currentKey === selectKey
              );
              let newElement;
              if (_.isNil(currentElement)) {
                newElement = { key: selectKey, values: [inputValue] };
              } else {
                const currentValues = currentElement.values || [];
                newElement = {
                  key: selectKey,
                  values: [...currentValues, inputValue],
                };
              }
              const auxAuthorityInformationAccess = _.filter(
                authorityInformationAccess,
                ({ key: storedKey }: { key: string; values: string[] }) =>
                  storedKey !== selectKey
              );
              const newValue = [...auxAuthorityInformationAccess, newElement];
              onChange({ key: 'authorityInformationAccess', value: newValue });
            }}
            onInputChange={({
              selectValue,
              inputValue,
            }: {
              selectValue: string;
              inputValue: string;
            }): void => {
              setCurrentAIA({ key: selectValue, value: inputValue });
            }}
            label="Authority Information Access"
            options={Object.values(AIAAccessOptions)}
          />
        )}
        {readOnly && (
          <Label className="aia-label pki-label">
            Subject Authority Information Access
          </Label>
        )}
        <Table
          keyField="id"
          id="authority-information-access-output"
          search={false}
          noDataIndication={
            readOnly
              ? 'No Authority Information Access'
              : 'Add a New Authority Information Access'
          }
          data={_.map(
            authorityInformationAccess,
            (item: { key: string; values: string[] }, index) => ({
              ...item,
              key: keyToValue(AIAAccessOptions)[item.key],
              id: index,
            })
          )}
          columns={getTableColumns(
            'authorityInformationAccess',
            authorityInformationAccess,
            ['Type', 'Values', ''],
            ({ id: rowId }: { id: number }) => {
              const newValues = _.filter(
                authorityInformationAccess,
                (item, itemIndex) => rowId !== itemIndex
              );
              onChange({ key: 'authorityInformationAccess', value: newValues });
            },
            readOnly
          )}
        />
      </div>
    </div>
  );
};

export default Extensions;
