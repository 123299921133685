import React, { FC, useEffect, useState } from 'react';
import { get, includes, isEmpty, pick } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { AsymmetricKey } from '../../../types';
import { keySpecOptions } from '../../../../../libs/constants';
import { Select, Spinner } from '../../../../../components';

const defaultValues = {
  label: '',
  notes: '',
  asymmetricKeyId: '',
  uuid: undefined,
};

const formKeys = ['uuid', 'label', 'asymmetricKeyId', 'keySpec', 'notes'];

interface AsymmetricKeyFormProps {
  readOnly: boolean;
  asymmetricKey?: AsymmetricKey;
  onSubmit?: (formValues: Partial<AsymmetricKey>) => void;
  onModeChange: Function;
  isLoading?: boolean;
  onCancel: Function;
}

export const AsymmetricKeyForm: FC<AsymmetricKeyFormProps> = ({
  readOnly,
  asymmetricKey,
  onSubmit,
  onModeChange,
  isLoading = false,
  onCancel,
}) => {
  const [formValues, setFormValues] = useState<Partial<AsymmetricKey>>(
    defaultValues
  );
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const isEditable =
    asymmetricKey === undefined || includes(asymmetricKey?.actions, 'update');
  useEffect(() => {
    setFieldsWithError([]);
    if (asymmetricKey) {
      setFormValues(pick(asymmetricKey, formKeys));
    }
  }, [readOnly, asymmetricKey, onSubmit]);

  const {
    label = '',
    uuid,
    asymmetricKeyId = '',
    keySpec = '',
    notes = '',
  } = formValues;

  const validateAndSubmit = () => {
    const errors: string[] = [];
    ['label'].forEach((property) => {
      if (isEmpty(get(formValues, property))) {
        errors.push(property);
      }
    });
    setFieldsWithError(errors);
    if (errors.length === 0 && onSubmit !== undefined) {
      onSubmit(formValues);
    }
  };
  const onChange = (property: keyof typeof formValues, value: any) => {
    if (formKeys.includes(property)) {
      setFormValues({
        ...formValues,
        [property]: value,
      });
    }
  };

  return (
    <div id={'AsymmetricKeyForm'}>
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>Asymmetric Key</h3>
          <span
            onClick={(): void => {
              onModeChange();
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row form>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="label">
                Label{readOnly ? '' : ' *'}
              </Label>
              <Input
                id="label"
                invalid={fieldsWithError?.includes('label')}
                value={label}
                readOnly={readOnly}
                plaintext={readOnly}
                onChange={(ev) => onChange('label', ev.target.value)}
                type="text"
                name="label"
                placeholder="Label"
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="asymmetricKeyId">
                Key ID
              </Label>
              <Input
                id="asymmetricKeyId"
                invalid={fieldsWithError?.includes('asymmetricKeyId')}
                value={asymmetricKeyId || (readOnly ? '-' : '')}
                readOnly={readOnly}
                plaintext={readOnly}
                onChange={(ev) => onChange('asymmetricKeyId', ev.target.value)}
                type="text"
                name="asymmetricKeyId"
                placeholder="Key ID"
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className="pki-label" for="keySpec">
                Specification
              </Label>
              <Input
                id="keySpec"
                invalid={fieldsWithError?.includes('keySpec')}
                value={
                  keySpecOptions.find((keyOption) => keyOption.key === keySpec)
                    ?.value || (readOnly ? '-' : '')
                }
                readOnly={true}
                plaintext={true}
                type="text"
                name="keySpec"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="notes">
                Notes
              </Label>
              <Input
                id="notes"
                value={notes || ''}
                readOnly={readOnly}
                plaintext={readOnly}
                onChange={(ev) => onChange('notes', ev.target.value)}
                type="textarea"
                name="notes"
                placeholder={readOnly ? 'N/A' : 'Notes'}
              />
            </FormGroup>
          </Col>
        </Row>
        {!readOnly && onSubmit !== undefined && (
          <div className="modal-buttons">
            <div className="float-right mt-5 pb-5 d-flex">
              <>
                <span className="mr-2">
                  <Button
                    id={`close-asymmetric-key-form`}
                    outline
                    disabled={readOnly || isLoading}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    id={`submit-asymmetric-key-form`}
                    outline
                    disabled={readOnly || isLoading}
                    onClick={validateAndSubmit}
                  >
                    Confirm
                  </Button>
                </span>
              </>
              {isLoading && (
                <Spinner
                  size={'sm'}
                  className={'ml-2 btn-group-vertical'}
                  type={'border'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
