import React, { FC } from 'react';
import { CustomInput } from 'reactstrap';

interface AwesomeCheckboxI {
  id: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: Function;
}

const AwesomeCheckbox: FC<AwesomeCheckboxI> = ({
  checked = false,
  disabled = false,
  onChange = (): null => null,
  id,
  label = '',
}) => {
  return (
    <div className="AwesomeCheckbox">
      <CustomInput
        type="checkbox"
        className="CheckboxList"
        id={id}
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          onChange(event);
        }}
      />
    </div>
  );
};

export default AwesomeCheckbox;
