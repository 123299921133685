/* eslint-disable react/jsx-no-undef */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  createRef,
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import _, { isEmpty, each, keys, includes, isBoolean } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import BootstrapTable, {
  BootstrapTableProps,
} from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { usePreviousNumber } from 'react-hooks-use-previous';
import { Alert, Button } from 'reactstrap';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit';
import ButtonDropdown from '../ButtonDropdown';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './Table.scss';

export interface TableProps {
  data: object[]; // { [index: string]: string | number | boolean | string[] }[];
  columns: {
    dataField: string;
    text: string;
    hidden?: boolean;
    sort?: boolean;
    csvExport?: boolean;
    csvFormatter?: Function;
    formatter?: Function;
  }[];
  striped?: boolean;
  keyField: string;
  toolbar?: ReactNode;
  pagination?: any;
  rowStyle?: object;
  remote?: boolean;
  onTableChange?: Function;
  sort?: {
    dataField: string;
    order: 'asc' | 'desc';
  };
  exportCSV?: {
    fileName?: string;
    onlyExportSelection?: boolean;
    exportAll?: boolean;
  };
  search?: boolean;
  noDataIndication?: string;
  id?: string;
  selectRow?: BootstrapTableProps['selectRow'];
  rowEvents?: {
    onClick?: Function;
  };
  nonSelectable?: string[] | number[];
  loading?: boolean;
  classes?: string;
  expandable?: boolean;
  ExpandedComponent?: FC<{ row: any; rowIndex?: number }>;
}

const Table: FC<TableProps> = ({
  classes,
  columns,
  data,
  keyField,
  toolbar,
  rowStyle,
  exportCSV,
  remote = false,
  pagination = true,
  search = true,
  striped = true,
  loading = false,
  sort,
  onTableChange = (): null => null,
  rowEvents,
  noDataIndication,
  selectRow,
  id,
  expandable = false,
  ExpandedComponent,
}) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any[] | null>(null);

  const containerDivRef = useRef<HTMLElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  useLayoutEffect(() => {
    const handleResize = () => {
      setContainerWidth(containerDivRef.current.parentElement.clientWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerDivRef]);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const _columns = [
    ..._.map(columns, (col) => ({
      ...col,
      sort: isBoolean(col.sort) ? col.sort : true,
    })),
    { dataField: '___hiddenId', text: '', hidden: true, csvExport: false },
  ];
  const prevDataSize = usePreviousNumber(_.size(data));

  const tableRef = useRef(null);

  const handleNextPage = ({
    page,
    onPageChange,
  }: {
    page: number;
    onPageChange: Function;
  }) => () => {
    onPageChange(page + 1);
  };

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);
  }, []);

  const handlePrevPage = ({
    page,
    onPageChange,
  }: {
    page: number;
    onPageChange: Function;
  }) => () => {
    onPageChange(page - 1);
  };

  const handleFirstPage = ({
    onPageChange,
  }: {
    onPageChange: Function;
  }) => () => {
    onPageChange(1);
  };

  const handleSizePerPage = (
    {
      onSizePerPageChange,
    }: {
      onSizePerPageChange: Function;
    },
    newSizePerPage: number
  ) => {
    onSizePerPageChange(newSizePerPage, 1);
  };

  const _selectRow = selectRow
    ? {
        selectRow: {
          ...selectRow,
          onSelect: (row: any, isSelected: boolean): void => {
            const { ___hiddenId } = row;
            if (isSelected) {
              setSelectedRows((current) => [...current, ___hiddenId]);
            } else {
              setSelectedRows((current) =>
                _.filter(current, (item) => item !== ___hiddenId)
              );
            }
            if (selectRow.onSelect) {
              selectRow.onSelect(row, isSelected);
            }
          },
          onSelectAll: (isSelected: boolean, rows: any[]): void => {
            if (isSelected) {
              setSelectedRows(_.map(rows, (item) => item.___hiddenId));
            } else {
              setSelectedRows([]);
            }
            if (selectRow.onSelectAll) {
              selectRow.onSelectAll(isSelected, rows);
            }
          },
        },
      }
    : {};

  if (expandable && !ExpandedComponent) {
    return (
      <Alert color={'danger'} className={'mt-2'}>
        <span>
          You misconfigured Table component by passing expandable without the
          ExpandedComponent
        </span>
      </Alert>
    );
  }
  return (
    <div className={`Table ${classes || ''}`} id={id} ref={containerDivRef}>
      <PaginationProvider
        pagination={paginationFactory({
          page: pagination?.page || undefined,
          custom: true,
          totalSize: remote ? 0 : data.length,
        })}
      >
        {({ paginationProps, paginationTableProps }) => {
          const nextPage = remote
            ? _.size(data) >= paginationProps?.sizePerPage
            : _.size(data) >
              paginationProps?.sizePerPage * paginationProps?.page;

          return (
            <>
              <ToolkitProvider
                keyField={keyField}
                data={filteredData || data}
                columns={_columns}
                sort={sort}
                search
                exportCSV={exportCSV}
              >
                {(props: any): ReactNode => (
                  <div>
                    <div className="topbar-container d-flex float-right mb-3">
                      {toolbar && toolbar}
                      {exportCSV && (
                        <ExportCSVButton
                          disabled={
                            exportCSV.onlyExportSelection &&
                            _.isEmpty(selectedRows)
                          }
                          className="btn btn-outline-secondary btn-sm mr-2"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Export to CSV
                        </ExportCSVButton>
                      )}
                      {search && (
                        <SearchBar
                          placeholder={
                            remote ? 'Search on current page...' : 'Search...'
                          }
                          className="form-control-sm mb-0"
                          {...props.searchProps}
                        />
                      )}
                    </div>
                    <BootstrapTable
                      striped={!expandable && striped}
                      ref={tableRef}
                      rowStyle={rowStyle}
                      hover
                      remote={remote}
                      {...(expandable
                        ? {
                            expandRow: {
                              showExpandColumn: true,
                              onlyOneExpanding: false,
                              className: (isExpanded, row, rowIndex) =>
                                `expandable-details bg-gray`,
                              parentClassName: (isExpanded, row, rowIndex) => {
                                return `expandable-row ${
                                  isExpanded ? 'expanded bg-gray' : 'collapsed'
                                }`;
                              },
                              renderer: (row, rowIndex) => (
                                <div
                                  className={
                                    'd-block expandable-content-container'
                                  }
                                  style={{
                                    width: containerWidth
                                      ? `${containerWidth}px`
                                      : 'auto',
                                  }}
                                >
                                  <ExpandedComponent row={row} />
                                </div>
                              ),
                              expandHeaderColumnRenderer: ({
                                isAnyExpands,
                              }) => <span></span>,
                              expandColumnRenderer: ({
                                expanded,
                                rowKey,
                                isExpandable,
                              }) =>
                                expanded ? (
                                  <FontAwesomeIcon icon={faChevronDown} />
                                ) : (
                                  <FontAwesomeIcon icon={faChevronRight} />
                                ),
                            },
                          }
                        : {})}
                      onTableChange={(type: any, newState: any): void => {
                        const checkState = newState;
                        if (checkState.page === 0) {
                          _.set(
                            tableRef,
                            'current.paginationContext.currPage',
                            1
                          );
                          checkState.page = 1;
                        }
                        if (isEmpty(checkState?.sortField)) {
                          checkState.sortField = sort?.dataField || '';
                        }
                        const { searchText } = checkState;
                        if (!isEmpty(searchText)) {
                          const output = _.filter(data, (item) => {
                            let match = false;
                            each(keys(item), (key) => {
                              if (
                                !match &&
                                includes(
                                  String(
                                    _.get(item, `[${key}]`)
                                  ).toLocaleLowerCase(),
                                  (searchText as string).toLocaleLowerCase()
                                )
                              ) {
                                match = true;
                              }
                            });
                            return match;
                          });
                          setFilteredData(output);
                        } else {
                          setFilteredData(null);
                        }
                        onTableChange(type, checkState);
                      }}
                      bootstrap4={true}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      defaultSorted={sort ? [sort] : []}
                      keyField={keyField}
                      noDataIndication={
                        loading ? (
                          <div className="text-center">Loading...</div>
                        ) : (
                          <div className="text-center">{noDataIndication}</div>
                        )
                      }
                      data={_.map(data, (item, index) => ({
                        ...item,
                        ___hiddenId: index,
                      }))}
                      columns={_columns}
                      onDataSizeChange={({
                        dataSize,
                      }: {
                        dataSize: number;
                      }): void => {
                        if (dataSize > prevDataSize) {
                          _.set(
                            tableRef,
                            'current.paginationContext.currPage',
                            1
                          );
                        }
                      }}
                      rowEvents={{
                        ...rowEvents,
                        onClick: (e: React.FormEvent, element: any): void => {
                          const selection = window.getSelection();
                          if (selection?.type === 'Range') {
                            e.preventDefault();
                          } else if (rowEvents?.onClick) {
                            rowEvents?.onClick(e, element);
                          }
                        },
                      }}
                      {..._selectRow}
                      rowClasses={!_.isNil(rowEvents) ? 'cursor-pointer' : null}
                      {...props.baseProps}
                      {...paginationTableProps}
                    />
                    {pagination !== false && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        className="table-pagination-container"
                      >
                        <div style={{ width: 200 }}>
                          {paginationProps?.page > 1 && (
                            <Button
                              className="mr-2 table-pagination-go-to-first-page"
                              size="sm"
                              outline
                              onClick={handleFirstPage(paginationProps)}
                            >
                              First Page
                            </Button>
                          )}
                          {paginationProps?.page > 1 && (
                            <Button
                              className="mr-2 table-pagination-prev-page"
                              size="sm"
                              outline
                              onClick={handlePrevPage(paginationProps)}
                            >
                              Previous Page
                            </Button>
                          )}
                        </div>
                        <Button
                          className="mr-2 table-pagination-current-page"
                          size="sm"
                          outline
                        >
                          {paginationProps?.page}
                        </Button>
                        <div
                          style={{
                            width: 200,
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                          className="table-pagination-page-size"
                        >
                          <ButtonDropdown
                            className="mr-2"
                            options={[
                              { key: 10, value: 10 },
                              { key: 25, value: 25 },
                              { key: 30, value: 30 },
                              { key: 50, value: 50 },
                            ]}
                            value={paginationProps?.sizePerPage}
                            onClick={({ key }: { key: string }): void => {
                              handleSizePerPage(paginationProps, key);
                            }}
                          />
                          {nextPage && (
                            <Button
                              className="mr-2 table-pagination-next-page"
                              size="sm"
                              outline
                              onClick={handleNextPage(paginationProps)}
                            >
                              Next Page
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </>
          );
        }}
      </PaginationProvider>
    </div>
  );
};

export default Table;
