import React, { FC, useEffect, useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../components';
import {
  IPatchProductProfile,
  ProductProfile as IProductProfile,
} from '../types';
import './ProductProfile.scss';
import { GeneralInfo } from './components/GeneralInfo/GeneralInfo';
import { deserializeProductProfile } from '../helpers';
import { ProductKeys } from './components/ProductKeys/ProductKeys';
import { api, shouldShowDemoContent } from '../../../libs/helpers';
import { sendNotification } from '../../../store/notifications/actions';
import { CertificateProfiles } from './components/CertificateProfiles/CertificateProfiles';
import { ProductMetadataSecrets } from './components/ProductMetadataSecrets/ProductMetadaSecrets';
import { NotFound } from '../../../components/NotFound/NotFound';
import { FactoryProvisioningData } from './components/FactoryProvisioningData/FactoryProvisioningData';
import { PGPKeys } from './components/PGPKeys/PGPKeys';

const DEFAULT_PROFILE_TABS: {
  label: string;
  slug: string | null;
  key: string;
}[] = [
  {
    key: 'GENERAL',
    label: 'General',
    slug: null,
  },
  {
    label: 'Product Keys',
    slug: 'keys',
    key: 'PRODUCT_KEYS',
  },
];

interface ProductProfileProps {
  tab?:
    | 'GENERAL'
    | 'PRODUCT_KEYS'
    | 'METADATA'
    | 'SECRETS'
    | 'CERTIFICATE_PROFILES'
    | 'PGP_KEYS'
    | 'FACTORY_PROVISIONING_DATA';
}

const ProductProfile: FC<ProductProfileProps> = ({ tab = 'GENERAL' }) => {
  const [showSpinner, setShowSpinner] = useState(true);

  const [productProfile, setProductProfile] = useState<IProductProfile>();
  const [mainStatusCode, setMainStatusCode] = useState(200);
  const [activeTab, setActiveTab] = useState(tab);
  const dispatch = useDispatch();

  const history = useHistory();
  const { id: profileId }: { id: string } = useParams();

  const PRODUCT_PROFILE_TABS = shouldShowDemoContent()
    ? [
        ...DEFAULT_PROFILE_TABS,
        {
          key: 'CERTIFICATE_PROFILES',
          label: 'Certificate Profiles',
          slug: 'certificate-profiles',
        },
        {
          key: 'METADATA',
          label: 'Metadata',
          slug: 'metadata',
        },
        {
          key: 'SECRETS',
          label: 'Secrets',
          slug: 'secrets',
        },
        {
          key: 'PGP_KEYS',
          label: 'PGP Keys',
          slug: 'pgp-keys',
        },
        {
          key: 'FACTORY_PROVISIONING_DATA',
          label: 'Device Batch Output',
          slug: 'output',
        },
      ]
    : DEFAULT_PROFILE_TABS;

  useEffect(() => {
    if (tab !== undefined) {
      setActiveTab(tab);
    }
  }, [tab]);

  const fetchProfile = async () => {
    setShowSpinner(true);
    try {
      const { data: profileResult } = await api().get(
        `product-profile/${profileId}`
      );
      setProductProfile(deserializeProductProfile(profileResult));
    } catch (err) {
      setMainStatusCode(err?.response?.status);
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Product Profile!';

      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setShowSpinner(false);
    }
  };

  const patchProductProfile: IPatchProductProfile = async (
    data,
    onSuccess,
    onFail
  ) => {
    setShowSpinner(true);
    try {
      const { data: editedProfile } = await api().patch(
        `product-profile/${profileId}`,
        data
      );
      setProductProfile(deserializeProductProfile(editedProfile));
      sendNotification({
        text: 'Product Profile has been updated successfully!',
        success: true,
      })(dispatch);
      if (onSuccess) onSuccess();
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong updating the Product Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      if (onFail) onFail(err);
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const onBackButtonClick = () => {
    history.push('/management/product-profiles');
  };

  if (mainStatusCode === 404) {
    return <NotFound toPrevious={true} />;
  }

  return (
    <div id="product-profile-single">
      <Card className="rounded p-5">
        <div className="header-container d-flex align-items-center mb-4">
          <div className="single-page-header">
            <FontAwesomeIcon
              onClick={onBackButtonClick}
              style={{ marginRight: '0.5rem' }}
              size="2x"
              className="pki-ico"
              icon={faChevronLeft}
            />
            <h3 className="text-muted">{productProfile?.name}</h3>
            {showSpinner && (
              <Spinner className="ml-2" size="sm" type="border" />
            )}
          </div>
        </div>
        <div>
          <Nav tabs>
            {PRODUCT_PROFILE_TABS.map(({ label, slug, key }) => {
              return (
                <NavItem
                  id={`nav-${kebabCase(key.toLowerCase())}`}
                  key={key}
                  className="cursor-pointer pki-label"
                >
                  <NavLink
                    className={classnames({ active: activeTab === key })}
                    onClick={(): void => {
                      if (slug) {
                        history.push(
                          `/management/product-profiles/${profileId}/${slug}`
                        );
                      } else {
                        history.push(
                          `/management/product-profiles/${profileId}`
                        );
                      }
                      setActiveTab(
                        (key as ProductProfileProps['tab']) || 'GENERAL'
                      );
                    }}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          {productProfile?.uuid && (
            <TabContent activeTab={activeTab}>
              <TabPane tabId={'GENERAL'} className="pt-3">
                {activeTab === 'GENERAL' && (
                  <GeneralInfo
                    isLoading={showSpinner}
                    profile={productProfile}
                    profileId={profileId}
                    onSubmit={patchProductProfile}
                  />
                )}
              </TabPane>
              <TabPane tabId={'PRODUCT_KEYS'} className="pt-3">
                {activeTab === 'PRODUCT_KEYS' && (
                  <ProductKeys
                    setShowSpinner={setShowSpinner}
                    profileActions={productProfile?.actions}
                    profileId={profileId}
                  />
                )}
              </TabPane>
              <TabPane tabId={'METADATA'} className="pt-3">
                <ProductMetadataSecrets
                  profileId={productProfile?.uuid}
                  profileActions={productProfile?.actions}
                  dataType={'metadata'}
                  isLoading={showSpinner}
                  profileData={productProfile?.metadata || []}
                  onSubmitData={patchProductProfile}
                />
              </TabPane>
              <TabPane tabId={'SECRETS'} className="pt-3">
                <ProductMetadataSecrets
                  profileId={productProfile?.uuid}
                  profileActions={productProfile?.actions}
                  dataType={'secret_data'}
                  isLoading={showSpinner}
                  profileData={productProfile?.secrets || []}
                  onSubmitData={patchProductProfile}
                />
              </TabPane>
              <TabPane tabId={'CERTIFICATE_PROFILES'} className="pt-3">
                <CertificateProfiles
                  patchProductProfile={patchProductProfile}
                  certificateProfileUuids={
                    productProfile.certificateProfileUuids || []
                  }
                  certificateProfiles={productProfile.certificateProfiles || []}
                  setShowSpinner={setShowSpinner}
                  profileActions={productProfile?.actions}
                  profileId={profileId}
                />
              </TabPane>
              <TabPane tabId={'PGP_KEYS'} className="pt-3">
                <PGPKeys
                  productProfile={productProfile}
                  onSubmitData={patchProductProfile}
                  pgpKeys={productProfile.pgpKeys || []}
                />
              </TabPane>
              <TabPane tabId={'FACTORY_PROVISIONING_DATA'} className="pt-3">
                <FactoryProvisioningData
                  clientCertProfile={productProfile?.certificateProfiles}
                  isLoading={showSpinner}
                  productProfile={productProfile}
                  onSubmitData={patchProductProfile}
                />
              </TabPane>
            </TabContent>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ProductProfile;
