import _, { includes, isEmpty } from 'lodash';
import React, { useState, FC, ReactNode, useEffect, MouseEvent } from 'react';
import { Modal, Card, UncontrolledPopover, Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faEdit,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableActions,
  Table,
  Spinner,
  ConfirmationForm,
  FilterBar,
} from '../../components';
import {
  api,
  getUrlWithFilters,
  onRemoveEntityRole,
  onSetEntityRole,
} from '../../libs/helpers';
import { Filter } from '../../components/FilterBar/types';
import { ProductProfile } from './types';
import { deserializeProductProfile, serializeProductProfile } from './helpers';
import { EntityType, UserOutput, UsersState } from '../../store/users/types';
import { ProductProfileForm } from './single/components/ProductProfileForm/ProductProfileForm';
import { sendNotification } from '../../store/notifications/actions';
import RolePolicy from '../ViewsComponents/RolePolicy';
import { ApplicationState } from '../../store';

interface ProductProfilesToolbarToolbarProps {
  onCreatProfileClick?: Function;
  addButtonDisabled?: boolean;
}

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'productProfileId':
      return 'product_profile_id';
    case 'updatedAt':
      return 'date_updated';
    case 'updatedBy':
      return 'updated_by_user_uuid';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

const ProductProfilesToolbar: FC<ProductProfilesToolbarToolbarProps> = ({
  onCreatProfileClick = (): null => null,
  addButtonDisabled = true,
}) => {
  return (
    <div className="AsymmetricKeysToolbar d-flex">
      <span className="mx-2">
        <Button
          id="add-new-product-profile"
          onClick={() => onCreatProfileClick()}
          disabled={addButtonDisabled}
          color={'secondary'}
          outline
          size={'sm'}
        >
          <>
            <FontAwesomeIcon icon={faPlusCircle} /> Product Profile
          </>
        </Button>
      </span>
    </div>
  );
};

const ProductProfiles: FC = () => {
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>([]);
  const [refetchRolePolicy, setRefetchRolePolicy] = useState(false);

  const [
    showProductProfileFormModal,
    setShowProductProfileFormModal,
  ] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [productProfilesList, setProductProfilesList] = useState<
    ProductProfile[]
  >([]);
  const [
    currentProductProfile,
    setCurrentProductProfile,
  ] = useState<ProductProfile>();
  const [canEditRoles, setCanEditRoles] = useState(false);

  const {
    userProfile: { uuid: currentUserUuid, resources },
  } = useSelector<ApplicationState, UsersState>((pki) => ({
    ...pki.users,
  }));

  const productProfileResource = resources.find(
    (resource) => resource.name === 'product_profile'
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      setShowContent(false);
      setShowSpinner(true);
      const urlWithParams = getUrlWithFilters('product-profile', {
        page: tablePage,
        sizePerPage: tableSizePerPage,
        sortBy: tableSortField,
        sortDir: tableSortOrder,
        query,
      });
      const { data: productProfiles } = await api().get(urlWithParams);
      setProductProfilesList(
        productProfiles.map((productProfile: any) =>
          deserializeProductProfile(productProfile)
        )
      );
      setShowContent(true);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching Product Profiles!';

      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tablePage, tableSizePerPage, tableSortOrder, tableSortField, query]);
  const toggleProductProfileFormModal = (): void => {
    setShowProductProfileFormModal((previousValue) => {
      if (previousValue) {
        setCurrentProductProfile(undefined);
      }
      return !previousValue;
    });
  };
  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentProductProfile(undefined);
      }
      return !previousValue;
    });
  };

  const onSubmitProductProfileForm = async (
    profileToSubmit: Partial<ProductProfile>
  ) => {
    try {
      await api().post(
        'product-profile',
        serializeProductProfile(profileToSubmit)
      );
      sendNotification({
        text: 'Product Profile has been created successfully!',
        success: true,
      })(dispatch);
      onCancelProductProfileForm();
      await fetchData();
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the Product Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const onCancelProductProfileForm = () => {
    setShowProductProfileFormModal(() => {
      setCurrentProductProfile(undefined);
      return false;
    });
  };

  const handleDeleteProductProfile = async (uuid: string) => {
    try {
      if (uuid) {
        await api().delete(`product-profile/${uuid}`);
        toggleDeleteModal();
        sendNotification({
          text: 'The Product Profile has been deleted successfully!',
          success: true,
        })(dispatch);
        await fetchData();
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong deleting the Product Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const toggleRolesModal = (): void => {
    setRolesModalOpen((previousValue) => {
      if (previousValue) {
        setCurrentProductProfile(undefined);
      }
      return !previousValue;
    });
  };

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (
        name: string,
        { uuid, notes }: { notes: string; uuid: string }
      ): ReactNode => {
        return (
          <>
            <div>{name}</div>
            {!isEmpty(notes) && (
              <div
                id={`product-profile-${uuid}-notes`}
                style={{ maxWidth: '600px' }}
                className="d-inline-block text-truncate"
              >
                <small> {notes}</small>
                <UncontrolledPopover
                  trigger="hover"
                  target={`product-profile-${uuid}-notes`}
                >
                  <div className="p-2">
                    <small>{notes}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    { dataField: 'productProfileId', text: 'REST API ID' },
    {
      dataField: 'updatedAt',
      text: 'Updated',
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      formatter: (
        createdBy: UserOutput,
        current: ProductProfile
      ): ReactNode => {
        return createdBy.email;
      },
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      formatter: (
        valueNotImportant: null,
        currentProfile: ProductProfile
      ): ReactNode => {
        const { actions } = currentProfile;
        const canUpdate = includes(actions, 'update');
        const canDelete = includes(actions, 'delete');
        const canEditPolicy = _.includes(actions, 'assign_roles');

        const options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        options.push({
          label: 'Edit',
          ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
          onClick: (e: MouseEvent): void => {
            e.stopPropagation();
            history.push(
              `/management/product-profiles/${currentProfile.uuid}?edit=true`,
              {
                productProfile: currentProfile,
              }
            );
          },
          disabled: !canUpdate,
        });

        options.push({
          label: 'Role Policy',
          ico: <FontAwesomeIcon className="pki-ico" icon={faUsers} />,
          onClick: (e: MouseEvent): void => {
            e.stopPropagation();
            setCanEditRoles(canEditPolicy);
            setCurrentProductProfile(currentProfile);
            setRolesModalOpen(true);
          },
        });

        options.push({
          label: 'Delete',
          ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
          onClick: (e: MouseEvent): void => {
            e.stopPropagation();
            setCurrentProductProfile(currentProfile);
            setDeleteModal(true);
          },
          disabled: !canDelete,
        });

        return (
          <TableActions rowId={String(currentProfile.uuid)} options={options} />
        );
      },
    },
  ];

  return (
    <div className="ProductProfiles">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Product Profiles</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'name',
                  label: 'Name',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'product_profile_id',
                  label: 'REST API ID',
                  type: 'filter',
                },
                {
                  key: 'notes',
                  label: 'Notes',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'created_by_user_uuid',
                  label: 'Created By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'created_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'updated_by_user_uuid',
                  label: 'Updated By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    searchPlaceholder: 'Type username...',
                    fetchUrl: '/user',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'updated_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Created Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Modified Date',
                  type: 'filter',
                },
              ]}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            <Table
              remote={true}
              search={false}
              pagination={{
                page: tablePage,
                sizePerPage: tableSizePerPage,
              }}
              keyField="uuid"
              sort={{
                dataField: tableSortField,
                order: tableSortOrder as SortOrder,
              }}
              noDataIndication={
                tablePage > 1
                  ? 'No more Product Profiles available'
                  : 'No Product Profile available'
              }
              data={productProfilesList}
              columns={columns}
              onTableChange={(
                valueNotUsed: null,
                {
                  page,
                  sizePerPage,
                  sortOrder,
                  sortField,
                }: {
                  page: number;
                  sizePerPage: number;
                  sortOrder: string;
                  sortField: string;
                }
              ): void => {
                setTablePage(page);
                setTableSizePerPage(sizePerPage);
                setTableSortOrder(sortOrder);
                setTableSortField(mapSortField(sortField));
              }}
              rowEvents={{
                onClick: (
                  notUsedValue: null,
                  current: ProductProfile
                ): void => {
                  history.push(`/management/product-profiles/${current.uuid}`, {
                    productProfile: current,
                  });
                },
              }}
              toolbar={
                <ProductProfilesToolbar
                  addButtonDisabled={
                    !productProfileResource?.actions?.includes('create')
                  }
                  onCreatProfileClick={(key: string): void => {
                    setShowProductProfileFormModal(() => {
                      setCurrentProductProfile(undefined);
                      return true;
                    });
                  }}
                />
              }
            />
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={showProductProfileFormModal}
        toggle={toggleProductProfileFormModal}
      >
        <ProductProfileForm
          onCancel={onCancelProductProfileForm}
          onSubmit={onSubmitProductProfileForm}
          isLoading={showSpinner}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title="Delete Product Profile Confirmation"
          message={''}
          challenge={{
            value: currentProductProfile?.name || '',
            label: 'Please type in the Product Profile Name',
            mismatchMessage:
              'The product profile name does not match.Please try again by typing the product profile name.',
          }}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>delete</strong> Product Profile "
                <strong>{currentProductProfile?.name}</strong>"
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={async (): Promise<void> => {
            if (currentProductProfile?.uuid) {
              await handleDeleteProductProfile(currentProductProfile.uuid);
            }
          }}
        />
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={rolesModalOpen}
        toggle={toggleRolesModal}
      >
        {currentProductProfile && (
          <RolePolicy
            readOnly={!canEditRoles}
            currentUserUuid={currentUserUuid}
            entity={currentProductProfile}
            entityType="ProductProfile"
            onCancel={toggleRolesModal}
            shouldFetch={refetchRolePolicy}
            setShouldFetch={setRefetchRolePolicy}
            onSetEntityRole={async (role: {
              entityType: EntityType.ProductKey;
              userUuids: string[];
              entityRole: string;
              entityUuid: string;
              usersAlreadyEntitled: string[];
            }) => {
              await onSetEntityRole(
                { ...role, type: 'User', inherit: false },
                dispatch,
                setRefetchRolePolicy
              );
            }}
            onRemoveEntityRole={async (role: {
              entityType: EntityType.ProductKey;
              userUuid: string;
              entityRole: string;
              entityUuid: string;
            }) => {
              await onRemoveEntityRole(role, dispatch, setRefetchRolePolicy);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default ProductProfiles;
