import React, { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';
import _, { includes } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal } from 'reactstrap';
import {
  ConfirmationForm,
  Table,
  TableActions,
} from '../../../../../components';
import { ProductMetadataSecretsForm } from './components/ProductMetadataSecretsForm/ProductMetadataSecretsForm';
import {
  IPatchProductProfile,
  ProductMetadataSecret as ProductMetadataSecretType,
  ProductProfileReport,
  ProductProfileRequest,
} from '../../../types';

interface ProductMetadataSecretsProps {
  dataType: 'metadata' | 'secret_data';
  isLoading: boolean;
  profileActions?: string[];
  profileId: string;
  profileData: ProductMetadataSecretType[];
  onSubmitData: IPatchProductProfile;
}

export const ProductMetadataSecrets: FC<ProductMetadataSecretsProps> = ({
  isLoading,
  profileActions = [],
  profileData,
  onSubmitData,
  dataType,
  profileId,
}) => {
  const [currentData, setCurrentData] = useState<ProductMetadataSecretType>();
  const [tablePage, setTablePage] = useState(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<'add' | 'edit'>('add');

  const dataTypeLabel =
    dataType === 'metadata' ? _.startCase(dataType) : 'Secret';

  const toggleFormModal = (): void => {
    setShowFormModal((previousValue) => {
      if (previousValue) {
        setFormMode('add');
        setCurrentData(undefined);
      }
      return !previousValue;
    });
  };

  const onSubmitForm = (data: ProductMetadataSecretType): void => {
    let newDataToSet: ProductMetadataSecretType[] = [];
    if (formMode === 'edit' && currentData) {
      newDataToSet = profileData.map((metaOrSecret) => {
        if (metaOrSecret.name === currentData.name) {
          return {
            name: data.name,
            value: data.value,
          };
        }
        return metaOrSecret;
      });
    } else if (formMode === 'add' && data.name && data.value) {
      newDataToSet = [...profileData, data];
    }
    const dataToSubmit: Partial<ProductProfileRequest> = {
      [dataType]: newDataToSet,
    };
    if (formMode === 'add' && data.name.trim() === 'base-mac') {
      dataToSubmit.product_identifier = '{ref:data.base-mac}';
      dataToSubmit.product_namespace = 'base-mac';
    }

    // Loop through each item in metadata and add appropriate report field until report
    // fields are configurable from UI side
    dataToSubmit.report = extractReportDataFromMetadata(
      dataToSubmit.metadata || []
    );
    onSubmitData(dataToSubmit, toggleFormModal);
  };

  const extractReportDataFromMetadata = (
    metadata: ProductMetadataSecretType[]
  ) => {
    const report: ProductProfileReport[] = [];
    [
      'model',
      'partner-id',
      'base-mac',
      'serial',
      'onu-serial',
      'prod-date',
      'hw-rev',
      'manuf',
      'oui',
      'mac-step',
    ].forEach((itemName) => {
      if (metadata?.findIndex((meta) => meta.name === itemName) > -1) {
        report.push({ name: itemName, value: `{ref:data.${itemName}}` });
      }
    });
    return report;
  };

  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentData(undefined);
        setShowFormModal(false);
      }
      return !previousValue;
    });
  };

  const onRowClick = (data: ProductMetadataSecretType) => {
    setFormMode(() => {
      setCurrentData(data);
      setShowFormModal(true);
      return 'edit';
    });
    return undefined;
  };
  const onCancelForm = () => {
    setShowFormModal(() => {
      setCurrentData(undefined);
      setFormMode('add');
      return false;
    });
  };

  const onAddData = () => {
    setShowFormModal(() => {
      setFormMode('add');
      setCurrentData(undefined);
      return true;
    });
  };

  const onConfirmRemove = async () => {
    if (currentData?.name) {
      const newDataToSet = profileData.filter(
        (metaOrSecret) => metaOrSecret.name !== currentData.name
      );
      let reportData: ProductProfileReport[] = [];
      if (dataType === 'metadata') {
        reportData = extractReportDataFromMetadata(newDataToSet || []);
      }
      onSubmitData(
        {
          [dataType]: newDataToSet,
          ...(dataType === 'metadata' ? { report: reportData } : {}),
        },
        toggleDeleteModal,
        toggleDeleteModal
      );
    }
  };

  const productMetadataSecretColumns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (
        name: string,
        currentMetaSecret: ProductMetadataSecretType
      ) => {
        return (
          // TODO can be the case in future to show it just for DT or get the
          //  identifier name from the backend
          <span>
            {name}
            {dataType === 'metadata' && name === 'base-mac' && (
              <FontAwesomeIcon className="pki-ico ml-1" icon={faKey} />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'value',
      text: 'Value',
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      style: { width: '100px' },
      formatter: (
        valueNotImportant: null,
        currentRow: ProductMetadataSecretType
      ): ReactNode => {
        const canDelete = includes(profileActions, 'update');
        const options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        options.push({
          label: 'Delete',
          ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
          onClick: async (e: MouseEvent): Promise<void> => {
            e.stopPropagation();
            setCurrentData(() => {
              setDeleteModal(true);
              return currentRow;
            });
          },
          disabled: !canDelete,
        });

        return (
          <TableActions rowId={String(currentRow.name)} options={options} />
        );
      },
    },
  ];

  if (!dataType)
    return (
      <Alert color={'danger'}>
        Please provide <pre>dataType</pre> prop!
      </Alert>
    );

  return (
    <div id={`product-profile-${dataType}`}>
      <div className="d-flex justify-content-end mb-2">
        <Button
          id="add-metadata"
          outline
          disabled={isLoading || !profileActions?.includes('update')}
          size="sm"
          onClick={onAddData}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> {dataTypeLabel}
        </Button>
      </div>
      <Table
        search={false}
        keyField="name"
        loading={isLoading}
        remote={false}
        data={profileData}
        columns={productMetadataSecretColumns}
        noDataIndication={
          tablePage > 1
            ? `No more ${dataTypeLabel} for current Product Profile`
            : `No ${dataTypeLabel} has been added to Product Profile`
        }
        pagination={{
          page: tablePage,
          sizePerPage: tableSizePerPage,
        }}
        onTableChange={(
          valueNotUsed: null,
          { page, sizePerPage }: { page: number; sizePerPage: number }
        ): void => {
          setTablePage(page);
          setTableSizePerPage(sizePerPage);
        }}
        rowEvents={{
          onClick: (
            notUsedValue: null,
            current: ProductMetadataSecretType
          ): void => {
            onRowClick(current);
          },
        }}
      />
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={showFormModal}
        toggle={toggleFormModal}
      >
        <ProductMetadataSecretsForm
          metadataOrSecret={currentData}
          dataType={dataType}
          onCancel={onCancelForm}
          profileId={profileId}
          onSubmit={onSubmitForm}
          formMode={formMode}
          isLoading={isLoading}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title={`Remove ${_.startCase(dataType)} Confirmation`}
          message={''}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>delete</strong> "
                <strong>{currentData?.name}</strong>" {_.startCase(dataType)}{' '}
                from current Product Profile.
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={onConfirmRemove}
        />
      </Modal>
    </div>
  );
};
