import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import _, { kebabCase, startCase } from 'lodash';
import { Spinner } from '../../../../../../../components';
import { ProductMetadataSecret as ProductMetadataSecretType } from '../../../../../types';
import { validateTemplateVariable } from '../../../../../../ViewsComponents/helpers';

interface ProductMetadataFormProps {
  formMode?: 'add' | 'edit';
  dataType: 'metadata' | 'secret_data';
  onSubmit: (data: ProductMetadataSecretType) => void;
  isLoading?: boolean;
  onCancel: Function;
  profileId: string;
  metadataOrSecret?: ProductMetadataSecretType;
}

export const ProductMetadataSecretsForm: FC<ProductMetadataFormProps> = ({
  onSubmit,
  isLoading = false,
  onCancel,
  profileId,
  dataType,
  formMode = 'add',
  metadataOrSecret,
}) => {
  const [dataName, setDataName] = useState<string>(
    metadataOrSecret?.name || ''
  );

  const [dataValue, setDataValue] = useState<string>(
    metadataOrSecret?.value || ''
  );

  const [validationMessage, setValidationMessage] = useState<string>();

  const [showErrors, setShowErrors] = useState(false);

  const validateAndSubmit = () => {
    setShowErrors(true);
    if (!dataName || !dataValue) return;
    const validationResult = validateTemplateVariable(dataValue);
    if (validationResult.isInvalid) {
      setValidationMessage(validationResult.message || '');
      return;
    }
    setShowErrors(false);
    onSubmit({
      name: dataName,
      value: dataValue,
    });
  };

  if (formMode === 'edit' && !metadataOrSecret) {
    return (
      <Alert color={'danger'}>
        The actual value was not passed for edit mode!
      </Alert>
    );
  }
  return (
    <div id={'ProductProfileMetadataForm'}>
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>
            {_.startCase(
              `${formMode} ${
                dataType === 'metadata' ? startCase(dataType) : 'Secret'
              }`
            )}
          </h3>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row form>
          <Col xs={12} sm={4}>
            <Label className="pki-label" for={'name'}>
              Name
            </Label>
            <FormGroup>
              <Input
                invalid={showErrors && !dataName}
                value={dataName || ''}
                type="text"
                required
                onChange={(ev) => setDataName(ev.target.value)}
                name="dataName"
                id="dataName"
              />
              <FormFeedback>Name is required!</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs={12} sm={8}>
            <Label className="pki-label" for={'dataValue'}>
              Value
            </Label>
            <FormGroup>
              <Input
                invalid={showErrors && (!dataName || !!validationMessage)}
                value={dataValue || ''}
                type="text"
                required
                onChange={(ev) => setDataValue(ev.target.value)}
                name="dataValue"
                id="dataValue"
              />
              <FormFeedback>
                {validationMessage || 'Value is required!'}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {onSubmit !== undefined && (
          <div className="modal-buttons">
            <div className="float-right mt-5 pb-5 d-flex">
              <>
                <span className="mr-2">
                  <Button
                    id={`close-product-key-form`}
                    outline
                    disabled={isLoading}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    id={`submit-product-profile-${formMode}-${kebabCase(
                      dataType
                    )}-form`}
                    outline
                    disabled={isLoading || !dataName || !dataValue}
                    onClick={validateAndSubmit}
                  >
                    Confirm
                  </Button>
                </span>
              </>
              {isLoading && (
                <Spinner
                  size={'sm'}
                  className={'ml-2 btn-group-vertical'}
                  type={'border'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
