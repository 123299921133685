import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
  FormGroup,
} from 'reactstrap';
import { Table } from '../../components/index';
import { isValidOID } from '../../libs/helpers';
import { getTableColumns } from './helpers';
import { certificatePolicies as defaultValues } from '../../libs/constants';
import AwesomeCheckbox from '../../components/AwesomeCheckbox';
import { CertificatePolicies as CertificatePoliciesValuesI } from '../../libs/types';

interface CertificatePoliciesI {
  id?: string;
  onChange?: Function;
  values: CertificatePoliciesValuesI;
  readOnly?: boolean;
}

const CertificatePolicies: FC<CertificatePoliciesI> = ({
  id = '',
  onChange = (): null => null,
  values = defaultValues,
  readOnly = false,
}) => {
  const certificatePolicies = values.certificatePolicies || [];
  const [currentPolicy, setCurrentPolicy] = useState<{
    key: string;
    uri: string;
  }>({ key: '', uri: '' });

  const uniqueOID =
    certificatePolicies.filter((item) => item.key === currentPolicy.key)
      .length > 0;

  const invalidCurrentPolicy =
    (!_.chain(currentPolicy.key).trim().isEmpty().value() &&
      !isValidOID(currentPolicy.key)) ||
    uniqueOID;

  const enableBtnAdd =
    isValidOID(currentPolicy.key) && !!currentPolicy.key && !uniqueOID;

  useEffect(() => {
    if (certificatePolicies.length === 0 && values.critical) {
      onChange({
        key: 'critical',
        value: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificatePolicies, values]);

  return (
    <div id={id} className="CertificatePolicies">
      <div className="certificate-policies mt-3">
        {!readOnly && (
          <div className="certificate-policies-input">
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label
                    className="pki-label"
                    for="certificate-policies-identifier"
                  >
                    Policy Identifier
                  </Label>
                  <Input
                    id="certificate-policies-identifier"
                    value={currentPolicy.key}
                    invalid={invalidCurrentPolicy}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      const newValue: string = event.target.value;
                      setCurrentPolicy((current) => {
                        const newState = { ...current, key: newValue };
                        return newState;
                      });
                    }}
                    type="text"
                    name="certificate-policies-identifier"
                    placeholder="Add OID..."
                  />
                  {uniqueOID && <FormFeedback>OID must be unique</FormFeedback>}
                  {invalidCurrentPolicy && !uniqueOID && (
                    <FormFeedback>Insert a valid OID</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label
                    className="pki-label"
                    for="certificate-policies-values"
                  >
                    Certificate Practice Statement URI
                  </Label>
                  <Input
                    id="certificate-policies-values"
                    placeholder="Add URI..."
                    value={currentPolicy.uri}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      const newValue: string = event.target.value;
                      setCurrentPolicy((current) => ({
                        ...current,
                        uri: newValue,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <Button
                  outline
                  className="float-right"
                  id="certificate-policies-button"
                  disabled={!enableBtnAdd}
                  onClick={(): void => {
                    if (
                      certificatePolicies.filter(
                        (item) => item.key === currentPolicy.key
                      ).length > 0
                    ) {
                      onChange({
                        key: 'certificatePolicies',
                        value: certificatePolicies.map((item) => {
                          if (item.key === currentPolicy.key) {
                            return {
                              ...item,
                              values: [...item.values, currentPolicy.uri],
                            };
                          }

                          return item;
                        }),
                      });
                    } else {
                      onChange({
                        key: 'certificatePolicies',
                        value: [
                          ...certificatePolicies,
                          {
                            key: currentPolicy.key,
                            values: [currentPolicy.uri],
                          },
                        ],
                      });
                    }
                    setCurrentPolicy({ key: '', uri: '' });
                  }}
                >
                  Add Policy
                </Button>
              </Col>
            </Row>
          </div>
        )}

        <div className="mt-3 mb-3">
          <Row form>
            <Col md={3}>
              <Label className="pki-label">Certificate Policy Extension</Label>
              <AwesomeCheckbox
                id="critical-checkbox"
                label="Critical"
                checked={!!values.critical}
                disabled={readOnly || certificatePolicies.length === 0}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  onChange({
                    key: 'critical',
                    value: event.target.checked,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="certificate-policies-table">
        <Table
          keyField="id"
          id="certificate-policies-output"
          search={false}
          noDataIndication={readOnly ? 'No Policies' : 'Add a New Policy'}
          data={_.map(
            certificatePolicies,
            (item: { key: string; values: string[] }, index) => ({
              ...item,
              id: index,
            })
          )}
          columns={getTableColumns(
            'certificatePolicies',
            certificatePolicies,
            ['Policy Identifier', 'Certificate Practice Statement URI', ''],
            ({ id: rowId }: { id: number }) => {
              const newValues = _.filter(
                certificatePolicies,
                (item, itemIndex) => rowId !== itemIndex
              );
              onChange({ key: 'certificatePolicies', value: newValues });
            },
            readOnly
          )}
        />
      </div>
    </div>
  );
};

export default CertificatePolicies;
