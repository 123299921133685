/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormGroup, Label, Form } from 'reactstrap';
import _, { isNil } from 'lodash';
import React, { FC, useState, ReactNode, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { Table, TableActions, Toggle } from '../../components';
import { User } from '../../store/users/types';
import { useUsersSelect } from '../../libs/hooks';
import { deserializeUser } from '../../store/users/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { sendNotification } from '../../store/notifications/actions';

interface Props {
  onChange: Function;
  approversIdList: string[];
  readOnly: boolean;
  notification?: boolean;
  id?: string;
  isRequestInvalid: boolean;
}

const ApprovalPolicy: FC<Props> = ({
  onChange = (): null => null,
  readOnly = false,
  approversIdList,
  notification,
  id = '',
  isRequestInvalid = false,
}) => {
  const [usersLoading, setUsersLoading] = useState(true);
  const [tableData, setTableData] = useState<Array<User>>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { fetchUser } = useUsersSelect();
  const dispatch = useDispatch();

  const userOptionFormatter = (user: User) =>
    `${user.username.replaceAll(/^service-account(-token)?-/g, '')} (${
      user.email || 'No Email Address'
    })`;

  const fetchUsers = async () => {
    let fetchedUsers: Array<User> = [];

    for (let i = 0; i < approversIdList.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const user = await fetchUser(approversIdList[i]);

      if (user) {
        fetchedUsers = [...fetchedUsers, user];
      }
    }

    setTableData(fetchedUsers);
    setUsersLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approversIdList]);

  const columns = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'username', text: 'Username' },
    { dataField: 'email', text: 'Email' },
    {
      dataField: '',
      text: '',
      formatExtraData: readOnly || approversIdList,
      formatter: (notUsedValue: null, { uuid }: { uuid: string }): ReactNode =>
        readOnly ? (
          ''
        ) : (
          <TableActions
            rowId={uuid}
            options={[
              {
                label: 'Remove',
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: (): void => {
                  const newValue = _.filter(
                    approversIdList,
                    (item) => item !== uuid
                  );
                  onChange(newValue, notification);
                },
              },
            ]}
          />
        ),
    },
  ];

  return (
    <div id={id} className="ApprovalPolicy">
      <div className="form-content mt-2">
        <div className="header-contanier d-flex">
          <Label className="pki-label">Approval Policy</Label>
        </div>
        <div className="inputs">
          {!readOnly && (
            <Form
              className="mb-5 row"
              onSubmit={(e: React.FormEvent): void => {
                e.preventDefault();
              }}
              inline
            >
              <FormGroup className="col-md-10">
                <ServerSideSelect
                  id="user-input"
                  value={selectedUser ? userOptionFormatter(selectedUser) : ''}
                  placeholder={'Select a user'}
                  searchPlaceholder={'Search username'}
                  fetchUrl={'user'}
                  onSelectEntity={(user: User) =>
                    setSelectedUser(deserializeUser(user))
                  }
                  pageUrlParam={'page'}
                  searchParam={'username'}
                  pageSize={50}
                  searchOperator={'~'}
                  formatter={userOptionFormatter}
                  isParentLoading={false}
                />
              </FormGroup>
              <div className="col-md-2">
                <Button
                  id="entitle-button"
                  className="w-100 select-height"
                  outline
                  value="Entitle as"
                  disabled={_.isEmpty(selectedUser)}
                  onClick={(): void => {
                    if (
                      selectedUser &&
                      !approversIdList.includes(selectedUser.uuid)
                    ) {
                      const newValue = [...approversIdList, selectedUser?.uuid];
                      setSelectedUser(null);
                      onChange(newValue, notification);
                    } else {
                      sendNotification({
                        text: 'Selected user was already added as an approver!',
                        success: false,
                      })(dispatch);
                    }
                  }}
                >
                  Add
                </Button>
              </div>
              {isRequestInvalid && (
                <div className="invalid-text subject-error">
                  Please select at least one Approval Policy User
                </div>
              )}
            </Form>
          )}
          {!isNil(notification) && (
            <div className="notification-policy d-flex">
              <Label className="pki-label mr-2">Send Notification</Label>
              <Toggle
                id="send-notification"
                disabled={readOnly}
                onChange={(checked: boolean): void => {
                  onChange(approversIdList, checked);
                }}
                checked={notification}
              />
            </div>
          )}
          <Table
            noDataIndication="No users yet"
            keyField="uuid"
            loading={usersLoading}
            columns={columns}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalPolicy;
