import React, { useState, useEffect, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { userDefaultValues } from './constants';
import { isValidEmail } from '../../libs/helpers';

import { User } from '../../store/users/types';

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onChangeMode?: Function;
  defaultValues?: User;
  readOnly: boolean;
}

const UserForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onChangeMode = (): null => null,
  defaultValues = userDefaultValues,
  readOnly = false,
}) => {
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [formValues, setFormValues] = useState<User>(defaultValues);

  const { username, email, firstName, lastName, enabled, uuid } = formValues;

  const isValid =
    !isEmpty(username) &&
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(email) &&
    isValidEmail(email);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setFormValues((current: User) => ({
      ...current,
      [property]: value,
    }));
  };

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);
  const hasUuid = !isEmpty(uuid);

  return (
    <div id="user-form" className="UserForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <div>
            <h3 className="text-muted">User</h3>
            {hasUuid && (
              <h6>
                <Badge color={enabled ? 'success' : 'danger'}>
                  {enabled ? 'Active' : 'Blocked'}
                </Badge>
              </h6>
            )}
          </div>
          <span
            onClick={(): void => {
              onChangeMode();
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {hasUuid && <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />}
          </span>
        </div>

        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row form>
          <Col>
            <FormGroup>
              <Label className="pki-label">Username</Label>
              <Input
                id="username"
                readOnly={readOnly || !isEmpty(uuid)}
                invalid={highlightMandatoryFields && isEmpty(username)}
                plaintext={readOnly || !isEmpty(uuid)}
                value={username}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'username',
                    value: event.target.value,
                  });
                }}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">Email</Label>
              <Input
                id="email"
                readOnly={readOnly || !isEmpty(uuid)}
                plaintext={readOnly || !isEmpty(uuid)}
                type="email"
                invalid={
                  (isEmpty(email) && highlightMandatoryFields) ||
                  (!isValidEmail(email) && highlightMandatoryFields)
                }
                value={email || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'email',
                    value: event.target.value,
                  });
                }}
              />
              <FormFeedback>
                Cannot be empty and must but a valid email format
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label className="pki-label">First Name</Label>
              <Input
                id="first-name"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields && isEmpty(firstName)}
                value={firstName || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'firstName',
                    value: event.target.value,
                  });
                }}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">Last Name</Label>
              <Input
                id="last-name"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields && isEmpty(lastName)}
                value={lastName || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'lastName',
                    value: event.target.value,
                  });
                }}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>
      {!readOnly && (
        <div className="float-right pb-5 pr-5">
          <span className="mr-2">
            <Button
              id="cancel-button"
              outline
              onClick={(): void => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </span>
          <span>
            <Button
              id="confirm-button"
              outline
              disabled={false}
              onClick={(): void => {
                if (!isValid) {
                  setHighlightMandatoryFields(true);
                }
                onSubmit({ values: formValues, isValid });
              }}
            >
              Confirm
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};

export default UserForm;
