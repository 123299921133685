import React, { FC } from 'react';
import _ from 'lodash';
import { Label, FormGroup, Row, Col, CustomInput } from 'reactstrap';
import './CheckboxList.scss';

interface Props {
  id?: string;
  options: {
    key: string;
    value: string;
  }[];
  checked: string[];
  elementsPerCol?: number;
  label?: string;
  readOnly?: boolean;
  radio?: boolean;
  onChange?: Function;
}

const CheckboxList: FC<Props> = ({
  options = [],
  checked = [],
  radio,
  elementsPerCol = 4,
  label,
  onChange = (): null => null,
  id,
  readOnly = false,
}: Props) => {
  const onChangeInput = ({
    key,
    checked: isChecked,
  }: {
    key: string;
    checked: boolean;
  }): void => {
    if (radio) {
      onChange(key);
    } else {
      const newValues = _.uniq([...checked, key]);
      if (!isChecked && _.includes(checked, key)) {
        _.remove(newValues, (el) => el === key);
      }
      onChange(newValues);
    }
  };

  const cols = _.ceil(_.size(options) / elementsPerCol);
  const outputCols = [];
  for (let col = 0; col < cols; col += 1) {
    const start = col * elementsPerCol;
    const end = start + elementsPerCol;
    outputCols.push(
      <Col key={col}>
        {_.map(
          _.slice(options, start, end),
          ({ value, key }: { value: string; key: string }, index) => (
            <div key={index}>
              <CustomInput
                type={radio ? 'radio' : 'checkbox'}
                id={key}
                disabled={readOnly}
                className="cursor-pointer"
                label={value}
                checked={_.includes(checked, key)}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  const {
                    target: { checked: isChecked },
                  } = event;
                  onChangeInput({ checked: isChecked, key });
                }}
              />
            </div>
          )
        )}
      </Col>
    );
  }
  return (
    <div id={id} className="CheckboxList">
      <FormGroup>
        {label && <Label className="pki-label">{label}</Label>}
        <Row>{outputCols}</Row>
      </FormGroup>
    </div>
  );
};

export default CheckboxList;
