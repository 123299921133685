import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Label,
  Button,
  Badge,
  FormGroup,
  UncontrolledPopover,
} from 'reactstrap';
import { Table, TableActions, Toggle } from '../../components';
import { BatchValuesI, PGPKey } from '../../libs/types';
import { dateFormatter, formatBytes, readFileAsText } from '../../libs/helpers';
import { sendNotification } from '../../store/notifications/actions';

interface BatchI {
  id?: string;
  onChange?: Function;
  values: BatchValuesI;
  readOnly?: boolean;
}

const Batch: FC<BatchI> = ({
  id = '',
  onChange = (): null => null,
  values,
  readOnly = false,
}) => {
  const [currentFiles, setCurrentFiles] = useState<Array<File>>([]);
  const inputEl = useRef<HTMLInputElement>(null);
  const { pgpKeys, batchEnabled } = values;
  const dispatch = useDispatch();

  const copyToClipboard = (content: string, confirmation: string) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    sendNotification({
      text: confirmation,
    })(dispatch);
  };

  const columns = useMemo(() => {
    const data = [
      { dataField: 'id', text: 'Id', hidden: true },
      {
        dataField: 'pgpKeyId',
        style: { width: '30%', textAlign: 'left' },
        text: 'PGP Key ID',
        formatter: (pgpKeyId: string): ReactNode => (
          <strong>
            <span className="value">{pgpKeyId}</span>
          </strong>
        ),
      },
      {
        dataField: 'name',
        style: { width: '30%', textAlign: 'left' },
        text: 'Name',
        formatter: (name: string): ReactNode => (
          <strong>
            <span className="value">{name}</span>
          </strong>
        ),
      },
      {
        dataField: 'pgpKeyIdV4',
        style: {
          width: '150',
          textAlign: 'left',
        },
        text: 'PGP Key ID v4',
        formatter: (pgpKeyIdV4: string): ReactNode => (
          <strong>
            <div
              className="textCopyPaste"
              onClick={() => {
                copyToClipboard(
                  pgpKeyIdV4,
                  'PGP Key ID v4 copied to clipboard.'
                );
              }}
            >
              <span className="value">{pgpKeyIdV4}</span>
            </div>
          </strong>
        ),
      },
      {
        dataField: 'fingerprint',
        style: {
          width: '150',
          textAlign: 'left',
        },
        text: 'Fingerprint',
        formatter: (fingerprint: string): ReactNode => (
          <strong>
            <div
              className="textCopyPaste"
              onClick={() => {
                copyToClipboard(
                  fingerprint,
                  'Fingerprint copied to clipboard.'
                );
              }}
            >
              <span className="value">{fingerprint}</span>
            </div>
          </strong>
        ),
      },
      {
        dataField: 'createdAt',
        style: { width: '30%', textAlign: 'left' },
        text: 'Date Created',
        formatter: (date: number): ReactNode => (
          <strong>
            <span className="value">{dateFormatter(date)}</span>
          </strong>
        ),
      },
      {
        dataField: 'enabled',
        text: 'Status',
        style: { width: '10%', textAlign: 'left' },
        formatter: (value: boolean): ReactNode => (
          <h6>
            <Badge color={value ? 'success' : 'danger'}>
              {value ? 'Enabled' : 'Disabled'}
            </Badge>
          </h6>
        ),
      },
    ];

    if (!readOnly) {
      data.push({
        dataField: 'noData',
        text: 'Actions',
        csvExport: false,
        formatter: (_notUsedValue: null, item: PGPKey): ReactNode => {
          return (
            <TableActions
              rowId={item.uuid}
              options={[
                {
                  label: 'Delete',

                  ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                  onClick: () => {
                    onChange({
                      key: 'pgpKeys',
                      value: pgpKeys?.filter(
                        (pgpKey) => pgpKey.pgpKeyId !== item.pgpKeyId
                      ),
                    });
                  },
                },
              ]}
            />
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, pgpKeys]);

  return (
    <div id={id} className="Batch">
      <div className="digest-container mt-3">
        <Row form>
          <Col md={3}>
            <Label className="pki-label">Batch Enabled</Label>
            <FontAwesomeIcon
              id="batch-enabled-hint"
              icon={faInfoCircle}
              className="pki-ico ml-1"
            />
            <UncontrolledPopover trigger="hover" target="batch-enabled-hint">
              <div className="p-2">
                <small>
                  Allow this Certificate Profile to be used for
                  <br />
                  Certificate Batch Requests.
                </small>
              </div>
            </UncontrolledPopover>
            <FormGroup>
              <Toggle
                id="enabled"
                disabled={readOnly}
                onChange={(checked: boolean): void => {
                  onChange({
                    key: 'batchEnabled',
                    value: checked,
                  });
                }}
                checked={batchEnabled}
              />
            </FormGroup>
          </Col>
        </Row>
        {!readOnly && (
          <div className="upload-code-image">
            <Label className="pki-label">PGP Keys</Label>
            <Row>
              <Col className="d-flex" md={4}>
                <Button
                  outline
                  id="upload-digst-button"
                  onClick={(): void => {
                    inputEl?.current?.click();
                  }}
                >
                  <FontAwesomeIcon className="mr-1" icon={faUpload} />
                  Select
                </Button>
                <div className="mt-auto ml-1">
                  <code className="black-code"> (ASCII Armored Format)</code>
                </div>
              </Col>
              <input
                type="file"
                id="file-input"
                className="d-none"
                multiple
                ref={inputEl}
                onChange={async ({ target: { files } }): Promise<void> => {
                  if (files && files.length > 0) {
                    setCurrentFiles(Array.from(files));

                    const textFiles = await Promise.all(
                      Array.from(files).map(async (file) => {
                        return readFileAsText(file);
                      })
                    );

                    onChange({
                      key: 'pgpFiles',
                      value: textFiles,
                    });
                  }
                }}
              />
            </Row>
            <Row>
              {currentFiles.length > 0 && (
                <Col>
                  {currentFiles.map((item) => (
                    <Row key={item.name}>
                      <Col className="d-flex" md={8}>
                        <div
                          id="file-description"
                          className="text-truncate mt-auto"
                        >
                          {item.name} ({formatBytes(item.size)})
                        </div>
                      </Col>
                      <Col className="d-flex" md={2}>
                        <FontAwesomeIcon
                          className="pki-ico file-delete ml-auto mt-4"
                          onClick={async (): Promise<void> => {
                            const filterFiles = currentFiles.filter(
                              (file) => file.name !== item.name
                            );

                            const textFiles = await Promise.all(
                              Array.from(filterFiles).map(async (file) => {
                                return readFileAsText(file);
                              })
                            );

                            onChange({
                              key: 'pgpFiles',
                              value: textFiles,
                            });

                            setCurrentFiles(filterFiles);
                          }}
                          icon={faTrash}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              )}
            </Row>
          </div>
        )}
      </div>
      <div className="batches-table mt-3">
        <Table
          keyField="id"
          id="batches-output"
          search={false}
          noDataIndication={readOnly ? 'No PGP Keys' : 'Add a New PGP Key'}
          data={pgpKeys || []}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Batch;
