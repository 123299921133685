import React, { FC, ReactNode, useState } from 'react';

import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

interface Props {
  title: string;
  content: string | ReactNode;
  message?: string;
  onCancel: Function;
  onConfirm: Function;
  challenge?: {
    value: string;
    label: string;
    mismatchMessage?: string;
  };
  mismatchMessage?: string;
}

const ConfirmationForm: FC<Props> = ({
  title,
  content,
  onCancel,
  message = 'Please, Confirm or Cancel your action.',
  onConfirm,
  challenge,
}) => {
  const [challengeValue, setChallengeValue] = useState<string>('');
  const [showError, setShowError] = useState(false);

  const onChallengeChange: React.ChangeEventHandler<HTMLInputElement> = (
    ev
  ) => {
    setShowError(false);
    setChallengeValue(ev.target.value);
  };
  const onConfirmClick = () => {
    if (challenge !== undefined && challengeValue !== challenge.value) {
      setShowError(true);
      return;
    }
    onConfirm();
  };
  return (
    <div className="ConfirmationForm p-3">
      <span className="text-center text-muted">
        <h3>{title}</h3>
      </span>
      <div className="content p-3">
        <div>{content}</div>
        <p className="text-center">{message}</p>
      </div>
      {challenge !== undefined ? (
        <Row form>
          <Col>
            <FormGroup>
              <Label
                className={'pki-label w-100 text-center'}
                for={'confirm-form-challenge-input'}
              >
                {challenge.label}
              </Label>
              <Input
                type={'text'}
                invalid={showError}
                onPaste={(ev) => ev.preventDefault()}
                onDrop={(ev) => ev.preventDefault()}
                autoComplete="false"
                id={'confirm-form-challenge-input'}
                placeholder={`Please type in ${challenge.value}`}
                required={true}
                value={challengeValue}
                onChange={onChallengeChange}
              />
              <FormFeedback>
                {challenge.mismatchMessage ||
                  'The challenge value does not match the expected value'}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <div className="d-flex justify-content-between px-3">
        <Button
          id="cancel-form-button"
          outline
          onClick={(): void => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button id="confirm-form-button" outline onClick={onConfirmClick}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationForm;
