import React, { FC, ReactNode } from 'react';
import _ from 'lodash';
import { Label, Input } from 'reactstrap';

interface SelectI {
  id?: string;
  label?: string | ReactNode;
  selectedKey?: string;
  options: {
    key: string | number | undefined;
    value: string;
  }[];
  multiple?: boolean;
  onChange?: Function;
  className?: string;
  invalid?: boolean;
  disabled?: boolean;
  name?: string;
  readOnly?: boolean;
}

const Select: FC<SelectI> = ({
  invalid = false,
  label,
  options = [],
  multiple = false,
  onChange = (): null => null,
  selectedKey = '',
  id,
  name,
  className = '',
  disabled = false,
  readOnly = false,
}) => {
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value: index },
    } = event;
    onChange(options[Number(index)]);
  };
  const currentIndex = _.findIndex(
    options,
    (option) => option.key === selectedKey
  );

  const currentValue = _.get(options, `[${currentIndex}].value`, 'N/A');

  return (
    <div id={`${id}-select-container`} className={`Select ${className}`}>
      {label && (
        <Label className="pki-label" for={id}>
          {label}
        </Label>
      )}
      {readOnly ? (
        <Input
          readOnly={readOnly}
          plaintext={readOnly}
          value={currentValue}
          type="text"
          name={name || id || `name-${label}`}
          id={id}
        />
      ) : (
        <Input
          disabled={disabled || readOnly}
          invalid={invalid}
          value={currentIndex}
          plaintext={readOnly}
          onChange={onChangeInput}
          type="select"
          name={name || id || `name-${label}`}
          id={id}
          multiple={multiple}
        >
          {_.map(options, (option, index) => (
            <option value={index} key={`${label}-option-${index}`}>
              {option.value}
            </option>
          ))}
        </Input>
      )}
    </div>
  );
};

export default Select;
